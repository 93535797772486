import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import bgimage from '../assets/BG.svg';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import Footer from './Footer';
import load from '../assets/loading.gif';

function MyBooking() {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [bookingData, setBookingData] = useState([]);
  const [filter, setFilter] = useState('confirmed');
  const [loading, setLoading] = useState(true);
  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Return empty string if the input is falsy
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  

  
  useEffect(() => {
    const fetchMyOrders = async () => {
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.post(
          'https://admin.tourismooze.com/api/public/my_orders',
          {},
          { headers }
        );

        console.log(response, 'full response');
        const MybookingData = response.data.data; 
        console.log(MybookingData, 'myorders');

        if (Array.isArray(MybookingData)) {
          setBookingData(MybookingData);
        } else {
          console.error('Expected an array but got:', MybookingData);
        }
      } catch (error) {
        console.error('Error fetching my orders:', error.response || error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchMyOrders();
  }, [token]);

 
  
  const filteredBookings = bookingData.filter((plan) => {
    if (filter === 'confirmed') return plan.states === 'captured';
    if (filter === 'inprogress') return plan.states === 'pending';
    if (filter === 'cancelled') return plan.states === 'failed';
    
    return false;

  });

  const confirmedCount = bookingData.filter(plan => plan.states === 'captured').length;
  const pendingCount = bookingData.filter(plan => plan.states === 'pending').length;
  const faildCount = bookingData.filter(plan => plan.states === 'failed').length;

  if (loading) {
    return <div className="text-primary flex items-center justify-center h-screen">
      <div className='loading w-20 h-20 mx-auto my-auto'>
        <img src={load} ></img>
      </div>
    </div>;
  }
 
  
  return (
    <div
      className="w-full h-screen overflow-scroll relative items-center justify-center"
      style={{ backgroundImage: `url(${bgimage})`, backgroundSize: 'cover' }}
    >
      <div className='w-full md:w-[86%] mx-auto'>
        <button
          onClick={() => navigate(-1)}
          className="flex items-center my-2 focus:outline-none bg-[#1E75BB] rounded-md text-white font-poppins px-2 py-1"
        >
          <IoIosArrowBack className="mr-2 text-white" size={18} />Go Back
        </button>
        <p className='text-center font-poppins font-[900] text-3xl py-4'>My Booking</p>

        <div className='flex w-full md:w-[80%] mx-auto justify-between my-4'>
          <div onClick={() => setFilter('confirmed')} className={`w-full cursor-pointer p-3 rounded-l-full text-center font-poppins border-r-[#11A59B] border-r[1px] ${filter === 'confirmed' ? 'bg-[#009187] text-white' : 'bg-[#AEF1EC] text-[#11A59B]' }`}>
          <span className='font-bold text-lg'>{confirmedCount}</span>   Confirmed
          </div>
          <div onClick={() => setFilter('inprogress')} className={`w-full cursor-pointer p-3 text-center font-poppins border-r-[#11A59B] border-r[1px] ${filter === 'inprogress' ? 'bg-[#009187] text-white' :'bg-[#AEF1EC] text-[#11A59B]' }`}>
          <span className='font-bold text-lg'>{pendingCount}</span>   In progress
          </div>
          <div onClick={() => setFilter('cancelled')} className={`w-full cursor-pointer p-3 rounded-r-full text-center font-poppins border-r-[#11A59B] border-r[1px] ${filter === 'cancelled' ?  'bg-[#009187] text-white' :'bg-[#AEF1EC] text-[#11A59B]'}`}>
          <span className='font-bold text-lg'>{faildCount}</span>  Expired 
          </div>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-between gap-2'>
        {filteredBookings.length > 0 ? (
  filteredBookings.map((popular) => (
    <div key={popular.order_id} className="bg-white p-5 rounded-md shadow-md w-full border-[1px] border-[#0BD1C4]">
      <p className="font-poppins text-[#006464] text-[20px] font-semibold py-2 px-2">{popular.package_name}</p>

      <div className=' bg-[#C6FFE4] p-2 py-4 ' style={{ backgroundColor: popular.states === 'captured' ? '#AEF1EC' : popular.states === 'pending' ? '#FFECA7' : '#FF9EAE' }}>
        <p className='text-[#202020] font-bold pb-2 px-2'>Payment {capitalizeFirstLetter(popular.states)}</p>
        <p className='font-poppins text-[#587893] px-2 font-light'>BOOING ID : {popular.order_id}</p>
        <p className='text-[#587893] font-poppins px-2'>Booked on:{popular.booking_date}</p>
        <p className='text-[#587893] font-poppins px-2'>Expired on:{popular.expiry_date}</p>
        <p className='font-poppins text-[#587893] px-2'>No of Travellers & Rooms: {popular.adults} Adults & {popular.children} Childrens and {popular.rooms} Rooms </p>
        
        <p className='text-[#587893] font-poppins px-2'>Order total:{popular.order_total}</p>
      </div>

      <button 
        onClick={() => navigate('/package-details', { state: { popular, isFromBooking: true } })} 
        className='font-poppins bg-[#1E75BB] flex p-5 rounded-full text-white mx-auto text-center mt-8 mb-2'
      >
        VIEW DETAILS
      </button>
    </div>
  ))
) : (
  <div className='text-center mx-auto container bg-lightgray mt-5'>
    <p>No bookings available.</p>
  </div>
)}
</div>

      </div>
       {/* footer section */}
       <Footer />
      {/* end body section */}
    </div>
  );
}

export default MyBooking;
