import React, { useContext, useEffect, useState, useTransition } from 'react';
import { AuthContext } from './AuthContext';
import bgimage from "../assets/BG.svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { IoIosArrowBack } from "react-icons/io";
import Footer from './Footer';
import train from '../assets/train.svg';
import irctc from '../assets/irctc-logo.png';
import load from '../assets/loading.gif';
import Swal from 'sweetalert2';

function OptionSearchResult() {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [isPending, startTransition] = useTransition();
    const [selectedDate, setSelectedDate] = useState(null); // State to hold the selected date
    const [trainData, setTrainData] = useState([]); // State to hold train data
    const [trainFareData, setTrainFareData] = useState({});
    
    const [loading, setLoading] = useState(false); // Loading state
    const [loading2, setLoading2] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state
    const location = useLocation();
    const trainDetails = location.state?.trainDetails; // Access the passed train data
    const searchPayload = location.state?.searchPayload; // The payload you sent
    const {payload, Details, istrainSearch, popular, isFromPlan, destinations } = location.state || {}; // Access the passed data
    console.log("Popular ID:", popular?.id);
    console.log("Payload:", payload);
    console.log("Booking Details:", Details);
    console.log("train Details:", trainData);
    console.log("LOcation:", location);
    console.log(isFromPlan, 'YESSS From Plan');
    // Get the departure date from the search payload
    const departureDate = searchPayload?.departDate;
    const fromEntityId = searchPayload?.fromEntityId;
    const toEntityId = searchPayload?.toEntityId;
    const { adults, children, booking_date, rooms, package_id } = payload || {};
        
    // Function to get the next 5 days including the departure date
    const getNextFiveDays = (startDate) => {
        const dates = [];
        const baseDate = new Date(startDate);
        for (let i = 0; i < 5; i++) {
            const nextDate = new Date(baseDate);
            nextDate.setDate(baseDate.getDate() + i);
            dates.push(nextDate);
        }
        return dates;
    };

    const nextFiveDays = getNextFiveDays(departureDate);

    // Function to search for trains based on selected date
    const searchTrains = async (date) => {
        setLoading(true);
        setError(null); // Reset error state
    
        try {
            const departDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
            console.log('Searching for trains on:', departDate); // Debugging line
    
            const url = `https://irctc1.p.rapidapi.com/api/v3/trainBetweenStations?fromStationCode=${fromEntityId}&toStationCode=${toEntityId}&dateOfJourney=${departDate}`;
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-RapidAPI-Key': '5622eb97b4msh85c8bb5b01f83d2p11e0e6jsne29c6bb31e6a',
                    'X-RapidAPI-Host': 'irctc1.p.rapidapi.com'
                },
            };
    
            const response = await fetch(url, options);
            const data = await response.json();
            console.log('Train Details:', data);
    
            // Check if train data exists in the response
            if (data.data && data.data && data.data.length > 0) {
                setTrainData(data.data);

                
                
            } else {
                setTrainData([]); // Clear Train data if no Trains found
                Swal.fire({
                    title: "No trains Available",
                    text: "No trains found for the selected date. Please try another date.",
                    icon: "info",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            console.error('Error fetching trains:', error);
            setError('Error fetching train data. Please try again.');
            setTrainData([]); // Clear train data on error
    
            Swal.fire({
                title: "Feature Under Maintenance",
                text: "An error occurred while fetching train data. Please try again later.",
                icon: "error",
                confirmButtonText: "Go Back"
            }).then(() => {
                navigate(-2); // Navigate back to the previous page after the alert
            });
        } finally {
            setLoading(false);
        }
    };
 


    const GetTrainFare = async (trainNumber) => {
      setLoading2(trainNumber);
      setError(null);
    
      try {
        console.log('Fetching fare for Train Number:', trainNumber);
    
        const url = `https://irctc1.p.rapidapi.com/api/v1/getFare?trainNo=${trainNumber}&fromStationCode=${fromEntityId}&toStationCode=${toEntityId}`;
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-RapidAPI-Key': '5622eb97b4msh85c8bb5b01f83d2p11e0e6jsne29c6bb31e6a',
            'X-RapidAPI-Host': 'irctc1.p.rapidapi.com'
          },
        };
    
        const response = await fetch(url, options);
        const data = await response.json();
        console.log('Train Fare Details:', data);
    
        if (data.data && data.data.length > 0) {
          setTrainFareData((prevFares) => ({
            ...prevFares,
            [trainNumber]: data.data // Associate fare data with train number
          }));
        } else {
          Swal.fire({
            title: "Fare Not Available",
            text: "Fare details not found for the selected train.",
            icon: "info",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.error('Error fetching fare:', error);
        setError('Error fetching train fare data. Please try again.');
      } finally {
        setLoading2(null);
      }
    };
    
    
 
  

    // Set initial train data and selected date on component mount
    useEffect(() => {
        if (trainDetails) {
            console.log('Initial train details:', trainDetails); // Debugging line
            setTrainData(trainDetails.data || []); // Set initial train data
            const initialDate = new Date(departureDate); // Set initial date from departure date
            setSelectedDate(initialDate); // Initialize with the departure date
            searchTrains(initialDate); // Fetch trains for the initial date
        }
    }, [trainDetails, departureDate]);

    // Effect to fetch trains when a new date is selected
    useEffect(() => {
        if (selectedDate) {
            searchTrains(selectedDate);
        }
    }, [selectedDate]);

    return (
        <div
        className="relative items-center justify-center"
        style={{
          backgroundImage: `url(${bgimage})`,
          backgroundSize: "50%",
          backgroundRepeat: "repeat",
        }}
      >
        <div className="relative z-10 container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white lg:p-8 md:p-5 sm:p-2 rounded-lg shadow-lg w-full md:w-auto  mt-5 mb-5">
            <div className="flex page-head items-end justify-end">
              <button
                onClick={() => navigate(-1)}
                className="flex items-center my-2 focus:outline-none bg-secondary rounded-md text-white px-3 py-2"
              >
                <IoIosArrowBack className="mr-1 text-white" size={15} />
                Go Back
              </button>
            </div>
      
            <div className="search-results-container">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center items-start">
                {/* Date Sort Column */}
                <div className="h-auto p-5 col-span-1">
                  <div className="date-sort">
                    <div className="text-left">
                      <p className="text-text">
                        Select your Train from <b>{fromEntityId}</b> to{" "}
                        <b>{toEntityId}</b> on{" "}
                        <b>
                          {selectedDate
                            ? selectedDate.toLocaleDateString("en-US", {
                                weekday: "long",
                                month: "long",
                                day: "numeric",
                              })
                            : ""}
                        </b>
                      </p>
                    </div>
                    {nextFiveDays.map((date, index) => (
                      <div className="dates mt-5" key={index}>
                        <div
                          onClick={() => {
                            startTransition(() => {
                              setSelectedDate(date); // Set the selected date on click
                            });
                          }}
                          className={`text-text text-center p-2 cursor-pointer ${
                            selectedDate &&
                            selectedDate.toDateString() === date.toDateString()
                              ? "bg-[#E4FFFF]"
                              : "bg-lightgray"
                          }`}
                        >
                          <p className="text-text font-medium">
                            {date.toLocaleDateString("en-US", {
                              weekday: "long",
                              month: "long",
                              day: "numeric",
                            })}
                          </p>
                          <p className="text-green-700 font-bold">AVAILABLE</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
      
                {/* Results Column */}
                <div className="border rounded-lg h-auto col-span-1 md:col-span-2">
  <div className="results p-5 overflow-y-auto h-screen">
    {loading ? (
      <div className="flex items-center justify-center h-screen">
        <div className='loading w-20 h-20 mx-auto my-auto'>
          <img src={load} alt="Loading" />
        </div>
      </div>
    ) : error ? (
      <p className="text-red-500">{error}</p>
    ) : trainData.length > 0 ? (
      trainData.map((data, index) => (
        <div className="result bg-lightgray p-2 rounded-md mb-2" key={index}>
          <div className="bg-white p-2 h-auto rounded-sm">
            <div className="head flex justify-between">
              <div className="option-details flex gap-1 items-center py-1">
                <div className="logo w-8 h-8 bg-lightgray rounded-sm">
                  <img src={irctc} alt="Indian Railway" className="w-full rounded-lg" />
                </div>
                <div className="name">
                  <p className="text-text text-sm">
                    {data.train_number} - {data.train_name}
                  </p>
                </div>
              </div>
              <div className="duration">
                <p className="text-text text-sm">
                  {data.duration} Hours - {data.distance} KM Distance
                </p>
              </div>
            </div>

            <div className="body flex justify-between py-2">
              <div className="from">
                <p className="location text-darktext font-bold text-lg">
                  {data.from_station_name}
                </p>
                <p className="location text-text font-regular text-sm">
                  {data.train_date}
                </p>
              </div>
              <div className="middle text-center items-center flex flex-col">
                <div className="icon w-8 h-8">
                  <img src={train} alt="Train" />
                </div>
                <p className="text-sm text-text">TO</p>
              </div>
              <div className="to">
                <p className="location text-darktext font-bold text-lg">
                  {data.to_station_name}
                </p>
                <p className="location text-text font-regular text-sm">
                {data.train_date}
                </p>
              </div>
            </div>

            {/* Conditional rendering */}
            
{trainFareData[data.train_number] ? (
  <div className="footer flex justify-end gap-2 mt-2 border-t border-lightgray pt-2">
    {trainFareData[data.train_number].map((fareInfo) => (
      <div className="fare-options border-2 border-[#e3e3e3] bg-lightgray p-2 rounded-md text-center" key={fareInfo.class_type}>
        
        
        <h6 className="">
          <b>{fareInfo.class_name}</b>
        </h6>
        <h6 className="">
          CLASS TYPE: <b>{fareInfo.class_type}</b>
        </h6>
        <h5 className="">
          <b>₹{fareInfo.fare}</b>
        </h5>

        {fareInfo.fare > 0 ? ( // Check if fare is greater than 0
          <button
            className="bg-[#11A59B] text-white md:px-10 px-2 py-1 text-center"
            onClick={() => {
             
              const fareDetails = {
                class_type: fareInfo.class_type,
                class_name: fareInfo.class_name,
                fare: fareInfo.fare,
              };
              
              if (isFromPlan) {
                navigate("/confirm-plan", {
                  state: {
                    payload,
                    destinations,
                     trainDetails: {
          ...data,
          fareDetails, // Include fare details
        },
                    isFromTrainSearch: true,
                  },
                });
              } else {
                navigate("/package-details", {
                  state: {
                    payload,
                    Details,
                    popular,
                    trainDetails: {
                      ...data,
                      fareDetails, // Include fare details
                    },
                    isFromTrainSearch: true,
                  },
                });
              }
            }}
          >
            Select
          </button>
        ) : (
          <p className="text-red-500">Tickets not available</p> // Message when fare is 0
        )}
      </div>
      
    ))}
  </div>
) : (
  <div className="footer flex justify-between">
    <div className="option-details flex gap-1 items-center py-1">
      {/* <div className="icon w-5 h-5 bg-lightgray rounded-sm"></div>
      <p className="text-sm text-text">Price: {}</p> */}
    </div>
    <button
      className="bg-[#11A59B] text-white md:px-10 px-4 py-2 text-center"
      onClick={() => GetTrainFare(data.train_number)} // Pass train_number here
    >
     {loading2 === data.train_number ? 'Loading fare details....' : 'VIEW FARE DETAILS'}
    </button>
  </div>
)}
          </div>
        </div>
      ))
    ) : (
      <p>No trains available for this date.</p>
    )}
  </div>
</div>



              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      
    );
}

export default OptionSearchResult;
