import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Popup from './components/Popup';
import Packages from './components/Packages';
import { AuthProvider } from './components/AuthContext';
import PackageDetails from './components/PackageDetails';
import Destinations from './components/Destinations';
import DestinationDetail from './components/DestinationDetail';
import ProfileDrawer from './components/ProfileDrawer';
import NotificationDrawer from './components/NotificationDrawer';
import PlanYourTrip from './components/PlanYourTrip';
import Header from './components/Header';
import HomeHeader from './components/HomeHeader';
import TrendingDestinatios from './components/TrendingDestinatios';
import PopularPackages from './components/PopularPackages';
import { Provider } from 'react-redux';
import store from './components/store';
import Payment from './components/Payment';
import MyBooking from './components/MyBooking';
import MybooingHeader from './components/MybooingHeader';
import ExploreDestination from './components/ExploreDestination';
import ConfirmPlan from './components/ConfirmPlan';
import SuccessPlan from './components/SuccessPlan';
import TripPlans from './components/TripPlans';
import EnquiryPackageDetails from './components/EnquiryPackageDetails';
import { Toaster } from "react-hot-toast";
import PlanDetail from './components/PlanDetail';
import FlightSearch from './components/FlightSearch';
import FlightSearchResult from './components/FlightSearchResult';
import FlightSearchResult2 from './components/FlightSearchResult2';
import TrainSearch from './components/TrainSearch';
import TrainSearchResult from './components/TrainSearchResult';
import TrainSearchResult2 from './components/TrainSearchResult2';
import LogoutConfirmationPopup from './components/LogoutConfirmation';


const App = () => {
  // Check token status from localStorage
  const tokenstatus = localStorage.getItem('token');
  const [popup, setPopup] = useState(false);

  // Handlers for popup and authentication
  const handleLoginClick = () => {
    if (tokenstatus == null) {
      setPopup(true); // Open popup if token is not present
    }
  };

  const handleLogOutClick = () => {
    localStorage.removeItem('token'); // Clear token from localStorage
    setPopup(false); // Close popup when logged out
  };

  const handleCloseClick = () => {
    setPopup(false); // Close the popup when the close button is clicked
  };

  // Use effect to show the popup only on first render or navigation back to home
  useEffect(() => {
    // Check token on every render or when navigating back
    if (tokenstatus == null) {
      setPopup(false); // Show popup only if no token exists
    } else {
      setPopup(false); // Ensure popup does not show if token exists
    }
  }, [tokenstatus]);

  return (
    <AuthProvider>
      <Provider store={store}>
        <Toaster />
        <Router>
          <Routes>
            {/* Home route with popup logic */}
            <Route
              path="/"
              element={
                <>
                  <HomeHeader
                    onLoginClick={handleLoginClick}
                    onLogoutClick={handleLogOutClick}
                  />
                  <Home />
                  {/* Show popup only if token is null and popup is true */}
                </>
              }
            />

            {/* Other routes */}
            <Route path="/destinations" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><Destinations /></>} />
            <Route path="/destination-details" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><DestinationDetail /></>} />
            <Route path="/packages" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><Packages /></>} />
            <Route path="/popular-packages" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><PopularPackages /></>} />
            <Route path="/package-details" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><PackageDetails /></>} />
            <Route path="/profile-drawer" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><ProfileDrawer /></>} />
            <Route path="/notification-drawer" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><NotificationDrawer /></>} />
            <Route path="/logout-confirmation" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><LogoutConfirmationPopup /></>} />

            {/* <Route path="/****" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><PlanYourTrip /></>} /> */}
            <Route path="/confirm-plan" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><ConfirmPlan /></>} />
            <Route path="/trending-destinations" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><TrendingDestinatios /></>} />
            <Route path="/payment-page" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><Payment /></>} />
            <Route path="/plan-your-trip" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><ExploreDestination /></>} />
            <Route path="/plan-success" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><SuccessPlan /></>} />
            <Route path="/my-booking" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><MyBooking /></>} />
            <Route path="/my-trip-plans" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><TripPlans /></>} />
            <Route path="/plan-detail" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><PlanDetail /></>} />
            <Route path="/enquiry-package-details" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><EnquiryPackageDetails /></>} />
            <Route path="/flight-search" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><FlightSearch /></>} />
            <Route path="/flight-oneway" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><FlightSearchResult /></>} />
            <Route path="/flight-roundtrip" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><FlightSearchResult2 /></>} />
            <Route path="/train-search" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><TrainSearch /></>} />
            <Route path="/train-oneway" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><TrainSearchResult /></>} />
            <Route path="/train-roundtrip" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><TrainSearchResult2 /></>} />

            <Route path="*" element={<><Header onLoginClick={handleLoginClick} onLogoutClick={handleLogOutClick} /><NotFound /></>} />
          
          </Routes>
        </Router>
        {tokenstatus === null && popup && (
          <Popup onCloseClick={handleCloseClick} />
        )}
      </Provider>
    </AuthProvider>
  );
};

export default App;
