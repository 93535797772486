import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import Popup from './Popup';
import { CircleXIcon } from "lucide-react";
import { Link } from "react-router-dom";

function NotificationDrawer({ onClose }) {
  const baseUrl = 'https://admin.tourismooze.com/storage/'; // Replace with your actual base URL
  const navigate = useNavigate()
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const MySwal = withReactContent(Swal);
  

  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Return empty string if the input is falsy
    return string.charAt(0).toUpperCase() + string.slice(1);
  };





  






  const handleCloseClick = () => {
    setIsPopupVisible(false)
  }



  return (
    <div>
      {isPopupVisible && <Popup onCloseClick={handleCloseClick} />}
      <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => onClose()}
        ></div>
      
        <div className='fixed top-0 right-0 h-full rounded-l-3xl bg-white shadow-lg sm:p-5 p-5 z-40 w-[80%] md:w-[50%] lg:w-[40%] xl:w-[30%]'>
          <button className="absolute top-4 right-4" onClick={() => onClose()}>
          <CircleXIcon className="text-gray-300 group-hover:text-gray-400" />
          </button>

        <div className=' head'>
            <h2 className="text-xl font-bold mb-4 font-poppins text-primary">
            Notifications
            </h2>            
        </div>
        <div className='notifications mt-4'>
            <div className='notifivation border-text bg-lightgray p-4 rounded-sm mt-2'>
                <h5 className='text-green-700 text-md font-semibold'>Package Confirmed</h5>
                <p className='text-text text-sm'> Your package <span className='text-primary'>Explore Kochi</span>has been confirmed</p>

            </div>
            <div className='notifivation border-text bg-lightgray p-4 rounded-sm mt-2'>
                <h5 className='text-md font-semibold text-red-500'>Package Cancelled</h5>
                <p className='text-text text-sm'> Your package <span className='text-primary'>Explore Kochi</span> has been Cancelled</p>
            </div>
        </div>
          
        </div>
    </div>
  );
}

export default NotificationDrawer;
