import React, { useState, useEffect, useTransition, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Footer from './Footer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "./AuthContext";
import bgimage from "../assets/BG.svg";
import Swal from 'sweetalert2';

function OptionSearch() {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isPending, startTransition] = useTransition();
    const [mainloading, setMainLoading] = useState(false);
    // Get data passed via location state
    const location = useLocation();
    const { payload, Details, popular, isFromPlan, destinations } = location.state || {};
    const { adults, children } = payload || {};
    // State variables
    const [tab, setTab] = useState('oneway');
    // State variables for One Way
    const [selectedDate, setSelectedDate] = useState(null);
    const [fromInputValue, setFromInputValue] = useState('');
    const [toInputValue, setToInputValue] = useState('');
    const [fromSuggestions, setFromSuggestions] = useState([]);
    const [toSuggestions, setToSuggestions] = useState([]);
    const [fromSkyId, setFromSkyId] = useState(''); // Store skyId only
    const [toSkyId, setToSkyId] = useState(''); // Store skyId only

    const [loadingFrom, setLoadingFrom] = useState(false);
    const [loadingTo, setLoadingTo] = useState(false);
    // State variables for ROund trip
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [fromInputValue2, setFromInputValue2] = useState('');
    const [toInputValue2, setToInputValue2] = useState('');
    const [fromSuggestions2, setFromSuggestions2] = useState([]);
    const [toSuggestions2, setToSuggestions2] = useState([]);
    const [fromSkyId2, setFromSkyId2] = useState(''); // Store skyId only
    const [toSkyId2, setToSkyId2] = useState(''); // Store skyId only

    const [loadingFrom2, setLoadingFrom2] = useState(false);
    const [loadingTo2, setLoadingTo2] = useState(false);


    // Log values for debugging
    console.log("Payload:", payload);
    console.log("Booking Details:", Details);
    console.log(isFromPlan, 'YESSS');

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []);

     // Handle tab selection
     const selectTab = (nextTab) => {
        startTransition(() => {
            setTab(nextTab);
        });
    };

    
    const fetchAirportSuggestions = async (query, inputType) => {
        const url = `https://sky-scanner3.p.rapidapi.com/flights/auto-complete?query=${query}`;
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-RapidAPI-Key': '7028f071admsh10de74e6a22b35dp188174jsn4dcc22eb9769',
                'X-RapidAPI-Host': 'sky-scanner3.p.rapidapi.com'
            }
        };
    
        // Set loading state
        setLoadingState(inputType, true);
    
        try {
            const data = await fetchData(url, options);
            updateSuggestions(inputType, data.data || []);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
            // Ensure loading is turned off regardless of success or failure
            setLoadingState(inputType, false);
        }
    };
    
    // Fetch data from the API
    const fetchData = async (url, options) => {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    };
    
    // Update suggestions based on the input type
    const updateSuggestions = (inputType, suggestions) => {
        if (inputType === 'from' || inputType === 'from2') {
            setFromSuggestions(suggestions);
            setFromSuggestions2(suggestions);
        } else if (inputType === 'to' || inputType === 'to2') {
            setToSuggestions(suggestions);
            setToSuggestions2(suggestions);
        }
    };
    
    // Set loading state based on input type
    const setLoadingState = (inputType, isLoading) => {
        if (inputType === 'from') {
            setLoadingFrom(isLoading);
        } else if (inputType === 'to') {
            setLoadingTo(isLoading);
        } else if (inputType === 'from2') {
            setLoadingFrom2(isLoading);
        } else if (inputType === 'to2') {
            setLoadingTo2(isLoading);
        }
    };

    // Debounced API calls for inputs
    useEffect(() => {
        if (!fromInputValue) return;
        setLoadingFrom(true);
        const delayDebounceFn = setTimeout(() => {
            if (fromInputValue.length > 3) {
                fetchAirportSuggestions(fromInputValue, 'from');
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [fromInputValue]);

    useEffect(() => {
        if (!toInputValue) return;
        setLoadingTo(true);
        const delayDebounceFn = setTimeout(() => {
            if (toInputValue.length > 3) {
                fetchAirportSuggestions(toInputValue, 'to');
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [toInputValue]);

    useEffect(() => {
        if (!fromInputValue2) return;
        setLoadingFrom2(true);
        const delayDebounceFn = setTimeout(() => {
            if (fromInputValue2.length > 3) {
                fetchAirportSuggestions(fromInputValue2, 'from2');
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [fromInputValue2]);

    useEffect(() => {
        if (!toInputValue2) return;
        setLoadingTo2(true);
        const delayDebounceFn = setTimeout(() => {
            if (toInputValue2.length > 3) {
                fetchAirportSuggestions(toInputValue2, 'to2');
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [toInputValue2]);

    // Handle input changes
    const handleInputChange = (event, inputType) => {
        if (inputType === 'from') {
            setFromInputValue(event.target.value);
            setFromSuggestions([]);
        } else if (inputType === 'to') {
            setToInputValue(event.target.value);
            setToSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion, inputType) => {
        if (inputType === 'from') {
            setFromInputValue(`${suggestion.presentation.skyId} - ${suggestion.presentation.title}`);
            setFromSkyId(suggestion.presentation.skyId); // Store only skyId for payload
            setFromSuggestions([]);
        } else if (inputType === 'to') {
            setToInputValue(`${suggestion.presentation.skyId} - ${suggestion.presentation.title}`);
            setToSkyId(suggestion.presentation.skyId); // Store only skyId for payload
            setToSuggestions([]);
        }
    };


    const handleInputChange2 = (event, inputType) => {
        if (inputType === 'from2') {
            setFromInputValue2(event.target.value);
            setFromSuggestions2([]);
        } else if (inputType === 'to2') {
            setToInputValue2(event.target.value);
            setToSuggestions2([]);
        }
    };

  


    const handleSuggestionClick2 = (suggestion, inputType) => {
        if (inputType === 'from2') {
            setFromInputValue2(`${suggestion.presentation.skyId} - ${suggestion.presentation.title}`);
            setFromSkyId2(suggestion.presentation.skyId); // Store only skyId for payload
            setFromSuggestions2([]);
        } else if (inputType === 'to2') {
            setToInputValue2(`${suggestion.presentation.skyId} - ${suggestion.presentation.title}`);
            setToSkyId2(suggestion.presentation.skyId); // Store only skyId for payload
            setToSuggestions2([]);
        }
    };

   

    console.log("Payload before navigation:", payload);
    console.log("Details before navigation:", Details);
    console.log("Popular before navigation:", popular);

    // Handle search for one-way flights
   


    const handleSearchFlights = async () => {
        if (!fromInputValue || !toInputValue || !selectedDate) {
            Swal.fire({
                title: "Missing Fields",
                text: "Please fill in all fields before searching.",
                icon: "warning",
                confirmButtonText: "OK"
            });
            return;
        }
    
        const fromEntityId = fromSkyId;
        const toEntityId = toSkyId;
        const departDate = new Date(selectedDate).toISOString().split('T')[0];
    
        const url = `https://sky-scanner3.p.rapidapi.com/flights/search-one-way?fromEntityId=${fromEntityId}&toEntityId=${toEntityId}&departDate=${departDate}&currency=INR&adults=${adults}&children=${children}`;
    
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-RapidAPI-Key': '7028f071admsh10de74e6a22b35dp188174jsn4dcc22eb9769',
                'X-RapidAPI-Host': 'sky-scanner3.p.rapidapi.com',
            },
        };
    
        try {
            setMainLoading(true);
            const response = await fetch(url, options);
            const data = await response.json();
    
            // Check if API response has errors and handle it
            if (data.status === false && data.message === "Errors") {
                Swal.fire({
                    title: "No flight data",
                    text: "No flight data available for the selected route.",
                    icon: "error",
                    confirmButtonText: "Go Back"
                }).then(() => {
                    navigate(-1); // Navigate back to the previous page after the alert
                });
                return; // Stop execution to avoid further processing
            }
    
            if (!response.ok) {
                throw new Error('API call failed');
            }
    
            const searchpayload = { fromEntityId, toEntityId, departDate };
    
            navigate('/flight-oneway', {
                state: {
                    popular,
                    flightDetails: data,
                    searchPayload: searchpayload,
                    payload,
                    Details,
                    destinations,
                    OnewayTrip: true,
                    isFlightSearch: !isFromPlan,  // Set to true if not from plan
                    PlanFLightSearch: isFromPlan, // Set to true if from plan
                },
            });
        } catch (error) {
            console.error('Error fetching flight details:', error);
    
            // Show SweetAlert for runtime errors (network errors, etc.)
            Swal.fire({
                title: "Feature Under Maintenance",
                text: "An error occurred while fetching flight data. Please try again later.",
                icon: "error",
                confirmButtonText: "Go Back"
            }).then(() => {
                navigate(-1); // Navigate back to the previous page after the alert
            });
        }
        finally {
            setMainLoading(false); // Ensure loading stops even if an error occurs
        }
    };
    


    // Handle search for round-trip flights

    const handleSearchFlights2 = async () => {
        if (!fromInputValue2 || !toInputValue2 || !selectedDate1 || !selectedDate2) {
            Swal.fire({
                title: "Missing Fields",
                text: "Please fill in all fields before searching.",
                icon: "warning",
                confirmButtonText: "OK"
            });
            return;
        }
    
        const fromEntityId = fromSkyId2; // should be the airport code or ID
        const toEntityId = toSkyId2; // should be the airport code or ID
        const departDate = new Date(selectedDate1).toISOString().split('T')[0];
        const returnDate = new Date(selectedDate2).toISOString().split('T')[0];
    
        const url = `https://sky-scanner3.p.rapidapi.com/flights/search-roundtrip?fromEntityId=${fromEntityId}&toEntityId=${toEntityId}&departDate=${departDate}&returnDate=${returnDate}&currency=INR&adults=${adults}&children=${children}`;
    
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-RapidAPI-Key': '7028f071admsh10de74e6a22b35dp188174jsn4dcc22eb9769',
                'X-RapidAPI-Host': 'sky-scanner3.p.rapidapi.com'
            }
        };
    
        try {
            setMainLoading(true);
            const response = await fetch(url, options);
            const data = await response.json();
            console.log('Round-trip Flight Details:', data); // Log the entire response for debugging
    
            if (!data || !data.data) {
                Swal.fire({
                    title: "No Flight Data",
                    text: "No flight data available for the selected route.",
                    icon: "error",
                    confirmButtonText: "OK"
                });
                return;
            }
    
            const searchpayload2 = { 
                fromEntityId, 
                toEntityId, 
                departDate, 
                returnDate 
            };
    
            navigate('/flight-roundtrip', {
                state: {
                  payload,
                  flightDetails: data, // Ensure that the flight details are correctly passed
                  searchPayload: searchpayload2, // Add the search payload here
                  Details,
                  popular,
                  destinations,
                  isFlightSearch: !isFromPlan,  // Set to true if not from plan
                  RoundTrip: true, // New flag for round-trip
                  PlanFLightSearch: isFromPlan, // Set to true if from plan
                }
              });
        } catch (error) {
            console.error('Error fetching round-trip flight details:', error);
    
            Swal.fire({
                title: "Feature Under Maintenance",
                text: "An error occurred while fetching flight data. Please try again later.",
                icon: "error",
                confirmButtonText: "Go Back"
            }).then(() => {
                navigate(-1); // Navigate back to the previous page after the alert
            });
        } finally {
            setMainLoading(false); // Ensure loading stops even if an error occurs
        }
    };

   



    return (
        <div className="relative items-center justify-center" style={{ backgroundImage: `url(${bgimage})`, backgroundSize: "50%", backgroundRepeat: "repeat" }}>
    <div className="relative z-10 flex items-center justify-center w-full">
        <div className="bg-white p-8 rounded-lg shadow-lg w-auto mx-2 mt-5 mb-5">
        

            {/* Navigation */}
            <div className='flex page-head items-end justify-end'>
                <button
                    onClick={() => navigate(-1)}
                    className="flex items-center my-2 focus:outline-none bg-secondary rounded-md text-white px-1 py-1"
                >
                    <IoIosArrowBack className="mr-1 text-white" size={15} />
                    Go Back
                </button>
            </div>

            {/* Search Options */}
            <div className='search-options w-auto min-w-[50%] max-w-[100%] rounded-lg border-2 border-lightgray p-5 mx-auto'>
                <h1 className='text-center justify-center font-poppins font-bold text-[20px] mb-4'>BOOK WITH FLIGHT</h1>

                {/* Tabs for One Way and Round Trip */}
                <ul className="flex flex-wrap text-sm font-medium text-center justify-center mb-5">
                    <li className="me-2">
                        <a href="#" onClick={(e) => { e.preventDefault(); selectTab('oneway'); }} 
                            className={tab === 'oneway' ? 
                                'inline-block p-4 px-8 rounded-lg border-solid border-2 border-primary font-bold text-white bg-primary' : 
                                'inline-block p-4 px-8 rounded-lg text-primary hover:text-white hover:bg-primary border-solid border-2 border-primary'}>
                            One way Only
                        </a>
                    </li>
                    <li className="me-2">
                        <a href="#" onClick={(e) => { e.preventDefault(); selectTab('roundtrip'); }} 
                            className={tab === 'roundtrip' ? 
                                'inline-block p-4 px-8 rounded-lg border-solid border-2 border-primary font-bold text-white bg-primary' : 
                                'inline-block p-4 px-8 rounded-lg text-primary hover:text-white hover:bg-primary border-solid border-2 border-primary'}>
                            Round Trip
                        </a>
                    </li>
                </ul>

                {/* One Way Tab Content */}
                {tab === 'oneway' && (
                    <div className='oneway flex justify-center items-center mt-5'>
                        <div className='oneway-tab'>
                            <p className='font-semibold font-sm mb-2'>One Way Details</p>

                            {/* From Input */}
                            <div className='mt-5 mb-5'>
                                <label className='flex flex-none mb-2'>From</label>
                                <input
                                    type="text"
                                    value={fromInputValue}
                                    onChange={(e) => handleInputChange(e, 'from')}
                                    placeholder="Enter departure airport"
                                    className="w-[350px] p-2 px-4 border border-[#dddddd] text-text rounded-3xl bg-lightgray"
                                />
                                {loadingFrom && <p>Loading...</p>}
                                <ul style={{ maxHeight: '150px', maxWidth: '400px', overflowY: 'auto' }}>
                                    {fromSuggestions.map((suggestion, index) => (
                                        <li key={index} onClick={() => handleSuggestionClick(suggestion, 'from')} style={{ cursor: 'pointer' }}>
                                            {suggestion.presentation.skyId} - {suggestion.presentation.title}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* To Input */}
                            <div className='mt-5 mb-5'>
                                <label className='flex flex-none mb-2'>To</label>
                                <input
                                    type="text"
                                    value={toInputValue}
                                    onChange={(e) => handleInputChange(e, 'to')}
                                    placeholder="Enter arrival airport"
                                    className="w-[350px] p-2 px-4 border border-[#dddddd] text-text rounded-3xl bg-lightgray"
                                />
                                {loadingTo && <p>Loading...</p>}
                                <ul style={{ maxHeight: '150px', maxWidth: '400px', overflowY: 'auto' }}>
                                    {toSuggestions.map((suggestion, index) => (
                                        <li key={index} onClick={() => handleSuggestionClick(suggestion, 'to')} style={{ cursor: 'pointer' }}>
                                            {suggestion.presentation.skyId} - {suggestion.presentation.title}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Date Picker */}
                            <div className='mt-5 mb-5'>
                                <label className='flex flex-none mb-2'>Choose a date</label>
                                <DatePicker 
                                    selected={selectedDate} 
                                    onChange={(date) => setSelectedDate(date)} 
                                    minDate={new Date()} 
                                    className='w-[350px] p-2 px-4 border border-[#dddddd] text-text rounded-3xl bg-lightgray' 
                                    placeholderText="Select a date" 
                                />
                            </div>

                            {/* Search Button */}
                            <div className='text-center'>
                                <button
                                    className='w-[300px] bg-[#11A59B] rounded-full px-10 py-3 text-white text-center'
                                    onClick={handleSearchFlights}
                                    disabled={!fromInputValue || !toInputValue || !selectedDate} // Disable button if any field is empty
                                >
                                     {mainloading ? 'Looking for flights...' : 'SEARCH FLIGHTS'}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Round Trip Tab Content */}
                {tab === 'roundtrip' && (
                    <div className='oneway flex justify-center items-center mt-5'>
                        <div className='oneway-tab'>
                            <p className='font-semibold font-sm mb-2'>Round Trip Details</p>

                            {/* From Input for Round Trip */}
                            <div className='mt-5 mb-5'>
                                <label className='flex flex-none mb-2'>From</label>
                                <input
                                    type="text"
                                    value={fromInputValue2}
                                    onChange={(e) => handleInputChange2(e, 'from2')}
                                    placeholder="Enter departure airport"
                                    className="w-[350px] p-2 px-4 border border-[#dddddd] text-text rounded-3xl bg-lightgray"
                                />
                                {loadingFrom2 && <p>Loading...</p>}
                                <ul style={{ maxHeight: '150px', maxWidth: '400px', overflowY: 'auto' }}>
                                    {fromSuggestions2.map((suggestion, index) => (
                                        <li key={index} onClick={() => handleSuggestionClick2(suggestion, 'from2')} style={{ cursor: 'pointer' }}>
                                            {suggestion.presentation.skyId} - {suggestion.presentation.title}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* To Input for Round Trip */}
                            <div className='mt-5 mb-5'>
                                <label className='flex flex-none mb-2'>To</label>
                                <input
                                    type="text"
                                    value={toInputValue2}
                                    onChange={(e) => handleInputChange2(e, 'to2')}
                                    placeholder="Enter arrival airport"
                                    className="w-[350px] p-2 px-4 border border-[#dddddd] text-text rounded-3xl bg-lightgray"
                                />
                                {loadingTo2 && <p>Loading...</p>}
                                <ul style={{ maxHeight: '150px', maxWidth: '400px', overflowY: 'auto' }}>
                                    {toSuggestions2.map((suggestion, index) => (
                                        <li key={index} onClick={() => handleSuggestionClick2(suggestion, 'to2')} style={{ cursor: 'pointer' }}>
                                            {suggestion.presentation.skyId} - {suggestion.presentation.title}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Departure Date Picker for Round Trip */}
                            <div className='mt-5 mb-5'>
                                <label className='flex flex-none mb-2'>Choose Departure Date</label>
                                <DatePicker 
                                    selected={selectedDate1} 
                                    onChange={(date) => setSelectedDate1(date)} 
                                    minDate={new Date()} 
                                    className='w-[350px] p-2 px-4 border border-[#dddddd] text-text rounded-3xl bg-lightgray' 
                                    placeholderText="Select a date" 
                                />
                            </div>

                            {/* Return Date Picker for Round Trip */}
                            <div className='mt-5 mb-5'>
                                <label className='flex flex-none mb-2'>Choose Return Date</label>
                                <DatePicker 
                                    selected={selectedDate2} 
                                    onChange={(date) => setSelectedDate2(date)} 
                                    minDate={new Date()} 
                                    className='w-[350px] p-2 px-4 border border-[#dddddd] text-text rounded-3xl bg-lightgray' 
                                    placeholderText="Select a date" 
                                />
                            </div>

                            {/* Search Button for Round Trip */}
                            <div className='text-center'>
                                <button
                                    className='w-[300px] bg-[#11A59B] rounded-full px-10 py-3 text-white text-center'
                                    onClick={handleSearchFlights2}
                                    disabled={!fromInputValue2 || !toInputValue2 || !selectedDate1 || !selectedDate2} // Disable button if any field is empty
                                >
                                     {mainloading ? 'Looking for flights...' : 'SEARCH FLIGHTS'}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>

       

    </div>
    <Footer />
</div>

    );
}

export default OptionSearch;

                            // <div className='inputs'>
                            //     <div className=' flex justify-center mt-5 gap-10 '>
                            //         <div className='oneway-tab'>
                            //             <p className='font-semibold font-sm mb-2'>Departure</p>
                            //             <div className=' mt-5 mb-5'>
                            //             <label className='flex flex-none mb-2'>From</label>
                            //             <input
                            //                 type="text"
                            //                 value={fromInputValue}
                            //                 onChange={(e) => handleInputChange(e, 'from')}
                            //                 placeholder="Enter departure airport"
                            //                 className="w-[350px] p-2 px-4 border border-[#dddddd]  text-text rounded-3xl bg-lightgray"
                            //             />
                            //             {loadingFrom && <p>Loading...</p>}
                            //             <ul style={{ maxHeight: '150px', maxWidth: '400px', overflowY: 'auto' }}>
                            //                 {fromSuggestions.map((suggestion, index) => (
                            //                     <li key={index} onClick={() => handleSuggestionClick(suggestion, 'from')} style={{ cursor: 'pointer' }}>
                            //                         {suggestion.presentation.skyId} - {suggestion.presentation.title}
                            //                     </li>
                            //                 ))}
                            //             </ul>



                            //         </div>

                            //         <div className=' mt-5 mb-5'>
                            //             <label className='flex flex-none mb-2'>To</label>
                            //             <input
                            //                 type="text"
                            //                 value={toInputValue}
                            //                 onChange={(e) => handleInputChange(e, 'to')}
                            //                 placeholder="Enter arraival airport"
                            //                 className="w-[350px] p-2 px-4 border border-[#dddddd]  text-text rounded-3xl bg-lightgray"
                            //             />
                            //              {loadingTo && <p>Loading...</p>}
                            //             <ul style={{ maxHeight: '150px', maxWidth: '400px', overflowY: 'auto' }}>
                            //                 {toSuggestions.map((suggestion, index) => (
                            //                     <li key={index} onClick={() => handleSuggestionClick(suggestion, 'to')} style={{ cursor: 'pointer' }}>
                            //                         {suggestion.presentation.skyId} - {suggestion.presentation.title}
                            //                     </li>
                            //                 ))}
                            //             </ul>

                            //         </div>


                            //         <div className=' mt-5 mb-5'>
                            //             <label className='flex flex-none mb-2'>Choose a date</label>
                            //             <DatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)} minDate={new Date()}   className='w-[350px] p-2 px-4 border border-[#dddddd]  text-text rounded-3xl bg-lightgray' placeholderText="Select a date" />
                            //         </div>
                            //         </div>

                            //         <div className='return-tab'>
                            //             <p className='font-semibold font-sm mb-2'>Return</p>
                            //             <div className=' mt-5 mb-5'>
                            //                 <label className='flex flex-none mb-2'>To</label>
                            //                 <input
                            //                     type="text"
                            //                     value={toInputValue}
                            //                     onChange={(event) => handleInputChange(event, 'to')}
                            //                     placeholder="Enter airport name or code"
                            //                     className="w-[350px] p-2 px-4 border border-[#dddddd]  text-text rounded-3xl bg-lightgray"
                            //                 />
                            //             </div>
                            //             <div className=' mt-5 mb-5'>
                            //                 <label className='flex flex-none mb-2'>Choose a return date</label>
                            //                 <DatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)} minDate={new Date()} className='w-[350px] p-2 px-4 border border-[#dddddd]  text-text rounded-3xl bg-lightgray' placeholderText="Select a return date" />
                            //             </div>
                            //         </div>
                            //     </div>
                            //     <div className='flex justify-center  mt-5 mb-5'>
                            //         <button className=' w-[300px] bg-[#11A59B] rounded-full px-20 py-3 text-white text-center'>SEARCH FLIGHTS</button>
                            //     </div>
                            // </div>