import React, { useState, useRef, useEffect } from "react";
import plogo from "../assets/plogo.svg";
import unlock from "../assets/unlocked.svg";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../assets/phslide1.png";
import slide2 from "../assets/phslide2.png";
import slide3 from "../assets/phslide1.png";
import { AuthContext } from "./AuthContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import {
  ArrowLeftCircleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckCircle2Icon,
} from "lucide-react";
import toast from "react-hot-toast";

const countryCodes = [
  { code: "+1", country: "US" },
  { code: "+91", country: "IN" },
  { code: "+44", country: "UK" },
];

const MobileInput = ({ onOtpSuccess }) => {
  const { setToken } = useContext(AuthContext);
  const { setUserEmail } = useContext(AuthContext);
  const { setUserName } = useContext(AuthContext);
  const { setProfilePic } = useContext(AuthContext);

  const dispatch = useDispatch();
  const [otpfeedback, setOtpFeedBack] = useState(false);
  const [registereduser, setRegisteredUser] = useState();
  const [registrationid, setRegistrationId] = useState();
  const [startCountdown, setStartCountdown] = useState(false);
  const [Login, setLogin] = useState(false);
  const [count, setCount] = useState(30); // Initial count value
  const sliderRef = useRef(null);
  const images = [slide1, slide2, slide3];
  const overlayTexts = [
    "Peaceful vacation with Touris Mooze.",
    "Explore, the Unexplored places.",
    "Peaceful vacation with Touris Mooze.",
  ];
  const overlayParagraphs = [
    "Venture into unknown realms, uncover hidden treasures, embrace the thrill of discovery—explore the unexplored with wonder!",
    "Dive deep into the mysteries of nature, discover hidden gems, and create unforgettable memories in the lap of untouched beauty.",
    "Embark on a journey of tranquility, immerse yourself in the serene beauty of nature, and rejuvenate your soul with Touris Mooze.",
  ];
  console.log("login state:", Login);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots, currentSlide) => (
      <div
        className="grid grid-cols-1 lg:grid-cols-2 items-center gap-y-0 lg:gap-y-2 px-0 md:px-20 md:pb-[5%] pb-[5%] "
        style={{ bottom: "4%", paddingLeft: "20px", paddingRight: "20px" }}
      >
        <ul className="flex justify-center xl:justify-start space-x-0 lg:m-0">
          {dots}
        </ul>
        
        <div className="flex justify-center xl:justify-end items-center xl:-mt-8 m-2">
          <button
            onClick={() => sliderRef.current.slickNext()}
            className="text-white border-none text-sm group backdrop-blur-lg bg-white/30 flex justify-center lg:justify-end items-center gap-2 py-1 px-4 lg:py-3 rounded-full"
          >
            NEXT
            <ArrowRightIcon
              size={18}
              className="group-hover:translate-x-1 transition-all duration-500 ease-in-out"
            />
          </button>
        </div>
      </div>
    ),
    customPaging: (i) => (
      <button className="block w-2.5 h-2.5 bg-gray-300 rounded-full"></button>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [mobile, setMobile] = useState("");
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  // const [countryName, setCountryName] = useState('IN');
  const [otp, setOtp] = useState(new Array(4).fill(""));

  // fetch profile information for set up in context
  // const fetchProfileData = async (reftoken) => {
  //   try {
  //     const response = await axios.post(
  //       'https://admin.tourismooze.com/api/public/profile',
  //       {}, // Empty payload if no data to send
  //       {
  //         headers: {
  //           'Authorization': `Bearer ${reftoken}`,
  //           'Content-Type': 'application/json',
  //         }
  //       }
  //     );

  //     const data = response.data.data;
  //     console.log('profiledata', data);

  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // end ptofile information for setup in context

  const handleChange = (e) => {
    setMobile(e.target.value);
  };

  const handleNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCountryCodeChange = (event) => {
    const selectedCode = event.target.value;
    const selectedCountry = countryCodes.find(
      (code) => code.code === selectedCode
    );

    setCountryCode(selectedCode);
    // setCountryName(selectedCountry.country)
    if (selectedCountry) {
      console.log("Selected Country Code:", selectedCode);
      console.log("Selected Country Name:", selectedCountry.country);
    }
  };

  const handleOtpChange = (element, index) => {
    if (/^\d*$/.test(element.value)) {
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);

      if (element.nextSibling && element.value) {
        element.nextSibling.focus();
      }
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!mobile) {
      toast("Enter mobile number");
    } else {
      setIsLoading(true);
      // const mobileNumber = countryCode + mobile;
      const formData = {
        mobile: mobile,
        phone_code: countryCode,
        //  country_code:countryName,
      };

      try {
        const response = await axios.post(
          "https://admin.tourismooze.com/api/public/login",
          formData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (response.data.status === true) {
          sliderRef.current.slickNext();
          setOtpFeedBack(true);
          setStartCountdown(true);
          setCount(30);
        }
      } catch (error) {
        console.error("Error uploading the data", error);
        // alert(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // registration
  const handleRegister = async (e) => {
    e.preventDefault();
    if (!mobile) {
      toast("Enter mobile number");
    } else {
      setIsLoading(false);
      // const mobileNumber = countryCode + mobile;
      const formData = {
        user_id: registrationid,
        name: fullname,
        email: email,
      };

      try {
        const response = await axios.post(
          "https://admin.tourismooze.com/api/public/register",
          formData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.data.status === true) {
          sliderRef.current.slickNext();
          console.log("user token", response.data.data.token);
          localStorage.setItem("token", response.data.data.token);
          setToken(response.data.data.token); // Set the token in context
          setEmail(response.data.data.email);
          setFullName(response.data.data.name);
          setUserName(response.data.data.name);
          setUserEmail(response.data.data.email);
          dispatch({ type: "SET_LOGIN", payload: true });
          onOtpSuccess();
          const reftoken = response.data.data.token;
          // fetchProfileData(reftoken);
        }
        if (response.data.status === false) {
          // alert(response.data.errors.email);
        }
      } catch (error) {
        console.error("Error uploading the data", error);
        // alert(error);
      }
    }
  };
  // end registration

  useEffect(() => {
    if (startCountdown) {
      const interval = setInterval(() => {
        if (count > 0) {
          setCount(count - 1);
        } else {
          clearInterval(interval); // Stop the countdown when count reaches 0
        }
      }, 1000); // Update count every second

      return () => clearInterval(interval); // Cleanup function to clear interval on unmount
    }
  }, [startCountdown, count]); // Run effect when startCountdown or count changes

  const submitOtp = async (e) => {
    e.preventDefault();
    const enteredDigits = otp.filter((digit) => digit !== "").length;
    if (enteredDigits < 4) {
      // alert("Complete OTP field");
    } else {
      const otpData = {
        otp: otp.join(""),
        mobile: mobile,
        phone_code: countryCode,
      };
      // Join OTP digits into a single string
      try {
        const otpresponse = await axios.post(
          "https://admin.tourismooze.com/api/public/verify",
          otpData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (
          otpresponse.data.status === true &&
          otpresponse.data.data.token === null
        ) {
          // alert(otpresponse.data.message);
          setRegisteredUser(true);
          setRegistrationId(otpresponse.data.data.id);
          sliderRef.current.slickNext();
          console.log(otpresponse.data.message);
        }
        if (
          otpresponse.data.status === true &&
          otpresponse.data.data.token !== null
        ) {
          dispatch({ type: "UPDATE_VALUE", payload: true });
          onOtpSuccess();
        }

        setOtpFeedBack(false);
        localStorage.setItem("token", otpresponse.data.data.token);
        setToken(otpresponse.data.data.token);
        setEmail(otpresponse.data.data.email);
        setFullName(otpresponse.data.data.name);
        setProfilePic(otpresponse.data.data.profile_pic);
        setUserName(otpresponse.data.data.name);
        setUserEmail(otpresponse.data.data.email);
        dispatch({ type: "SET_LOGIN", payload: true });
        setOtp(new Array(4).fill(""));
        // alert(otpresponse.data.message)
      } catch (error) {
        // alert(error);
        setOtpFeedBack(false);
      }
    }
  };

  return (
    <div className="sm:grid sm:grid-cols-2 mx-2 ">
      <div className="mx-auto w-full h-full hidden sm:block">
        <Slider {...settings} className="-mb-[8px] h-full" ref={sliderRef}>
          {images.map((img, index) => (
            <div key={index} className="slider-item relative">
              <img
                src={img}
                alt={`Slide ${index + 1}`}
                className="w-full rounded-l-2xl h-full"
              />
              <div className="overlay absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-80 rounded-l-2xl" />
              <div className="content absolute inset-0 flex flex-col justify-end my-3 items-center px-1 lg:px-4 md:pb-20 pb-5">
                <h2 className="text-white text-xl lg:text-2xl text-center font-bold mb-2">
                  {overlayTexts[index]}
                </h2>
                <p className="text-white text-sm xl:text-base text-center font-normal">
                  {overlayParagraphs[index]}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="md:p-6 p-4 flex justify-center items-center bg-white h-full">
        <form
          onSubmit={otpfeedback ? submitOtp : handleSubmit}
          className="flex flex-col items-center justify-center w-full max-w-sm md:gap-y-10 gap-y-5 h-full"
        >
          {!otpfeedback ? (
          <img src={plogo} className="object-contain mb-2 md:w-40 w-25 " />
        ):(
          <img src={unlock} className="object-contain mb-2 md:w-[7rem] w-[4rem]" />
        )}
          <div className="w-full">
            {!registereduser ? (
              <label
                className="block mb-5 text-darktext font-poppins md:text-md text-sm font-semibold"
                htmlFor="mobile"
              >
                {otpfeedback
                  ? "Confirm your phone number"
                  : "Enter your phone number"}
              </label>
            ) : (
              <div className="mb-5">
                <label className="block mb-[1px] lg:mb-2 text-darktext font-semibold md:text-md text-sm">
                More info needed
                </label>
                <p className=" hidden md:block text-secondary text-xs">{`We successfully verified your number ${mobile},  Please fill some more basic details to proceed`}</p>
              </div>
            )}

            {otpfeedback && !registereduser ? (
              <div className="px-1">
                <label
                  className="block text-darktext font-poppins text-sm font-regular  mb-5"
                  htmlFor="otp"
                >
                  
                </label>
                <div className="flex gap-[5px] md:gap-4 w-2">
                  {otp.map((data, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={data}
                      required
                      onChange={(e) => handleOtpChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                      className="w-8 h-8 md:w-12 md:h-12 rounded-lg bg-[#f3f3f3]  text-center  text-sm font-bold focus:outline-none focus:shadow-outline text-gray-600"
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex mb-[5px]4">
                {!registereduser ? (
                  <select
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                    className="bg-[#f3f3f3] bg-none lg:px-1 px-0 text-gray-500  focus:outline-none focus:shadow-outline lg:py-4 py-1 rounded-l-full"
                  >
                    {countryCodes.map((code) => (
                      <option key={code.code} value={code.code}>
                        {code.code}
                      </option>
                    ))}
                  </select>
                ) : (
                  ""
                )}

                {!registereduser ? (
                  <input
                    type="text"
                    id="mobile"
                    name="mobile"
                    value={mobile}
                    required
                    onChange={handleChange}
                    className="appearance-none bg-[#f3f3f3] lg:w-3/4 w-full lg:py-2 py-1 text-sm  px-4 text-[#4C4C4C] focus:outline-none focus:shadow-outline rounded-r-full"
                    placeholder="Phone number"
                  />
                ) : (
                  <div className="w-full gap-5">
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      value={fullname}
                      onChange={handleNameChange}
                      className="appearance-none lg:w-full w-full lg:py-3 my-2 py-1 text-sm px-4 text-[#4C4C4C] bg-[#f3f3f3] rounded-full "
                      placeholder="Your Full name"
                    />
                    <div className="relative lg:w-full w-full my-2 hidden">
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        value={mobile}
                        onChange={handleChange}
                        className="appearance-none lg:w-full w-full lg:py-3 my-2 py-1 text-sm px-4 text-[#4C4C4C] bg-[#f3f3f3] rounded-full"
                        placeholder="Phone number"
                        disabled
                      />
                    </div>
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      value={email}
                      onChange={handleEmailChange}
                      className="appearance-none lg:w-full w-full lg:py-3 my-2 py-1 text-sm px-4 text-[#4C4C4C] bg-[#f3f3f3] rounded-full "
                      placeholder="Email ID"
                    />
                  </div>
                )}
              </div>
            )}

            {!registereduser ? (
              <div>
                <p className="px-1  lg:py-2 py-[2px] font-poppins text-primary md:text-xs text-[10px] mt-3">
                  {otpfeedback
                    ? `Enter the verification code sent to ${mobile}`
                    : "We will send an OTP for verification to your number."}
                </p>

                {otpfeedback ? (
                  <p className="px-1 md:text-xs text-[12px] lg:py-2 py-[2px] font-poppins text-primary text-center">
                    {count === 0 ? "" : `Resend code in: ${count} seconds`}
                    {count === 0 ? (
                      <>
                        {`Didn't receive OTP?`}
                        <button
                          className="ml-2 md:text-xs text-[12px] text-gray-600 hover:text-gray-900 font-semibold"
                          onClick={() => {
                            setOtpFeedBack(false);
                            setOtp(new Array(4).fill(""));
                          }}
                        >
                          Resend Now
                        </button>
                      </>
                    ) : null}
                  </p>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className="w-full flex justify-center lg:mt-4 mt-1">
            {!registereduser ? (
              <button
                type="submit"
                disabled={isLoading}
                className="text-white font-poppins lg:font-semibold py-2 px-6 rounded-full 
                focus:outline-none focus:shadow-outline tracking-widest md:text-xs text-[10px] flex items-center gap-2 w-max bg-primary hover:scale-105 transition-all duration-300 ease-in-out disabled:opacity-50 uppercase"
              >
                {isLoading ? (
                  "Please wait..."
                ) : (
                  <>
                    <CheckCircle2Icon size={22} />
                    Confirm
                  </>
                )}
              </button>
            ) : (
              <button
                type="submit"
                disabled={isLoading}
                onClick={handleRegister}
                className="text-white font-poppins lg:font-semibold py-3 px-6 rounded-full 
                focus:outline-none focus:shadow-outline tracking-widest text-xs flex items-center gap-2 w-max bg-primary hover:scale-105 transition-all duration-300 ease-in-out disabled:opacity-50 uppercase"
              >
                {isLoading ? (
                  "Please wait"
                ) : (
                  <>
                    <CheckCircle2Icon size={22} />
                    Confirm
                  </>
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MobileInput;
