import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [currency, setCurrency] = useState('INR');
  const [profilePic, setProfilePic] = useState(localStorage.getItem('profilePic') || null);
  const [username, setUserName] = useState(localStorage.getItem('username') || null);
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || null);
  const [sugessiondata, setSugessionData] = useState();

  // Function to handle login
  const handleLogin = (userData) => {
    setUserName(userData.username);
    setUserEmail(userData.email);
    setProfilePic(userData.profilePic);
    setToken(userData.token);

    // Save token and other user info to localStorage
    localStorage.setItem('token', userData.token);
    localStorage.setItem('username', userData.username);
    localStorage.setItem('userEmail', userData.email);
    localStorage.setItem('profilePic', userData.profilePic);
  };

  const handleLogout = () => {
    setToken(null);
    setUserName(null);
    setUserEmail(null);
    setProfilePic(null);

    // Clear data from localStorage on logout
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('profilePic');
  };

  // Effect to load user data from localStorage when the component mounts
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
      setUserName(localStorage.getItem('username'));    // Restore username
      setUserEmail(localStorage.getItem('userEmail'));  // Restore email
      setProfilePic(localStorage.getItem('profilePic')); // Restore profile picture
    }
  }, []);

  return (
    <AuthContext.Provider value={{ 
      token, 
      currency, 
      profilePic, 
      username, 
      userEmail, 
      sugessiondata, 
      handleLogin, 
      setToken, 
      handleLogout, 
      setCurrency, 
      setSugessionData, 
      setProfilePic, 
      setUserName, 
      setUserEmail 
    }}>
      {children}
    </AuthContext.Provider>
  );
};
