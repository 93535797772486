import React, { useContext, useEffect, useState, useTransition } from 'react';
import { AuthContext } from './AuthContext';
import bgimage from "../assets/BG.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Footer from './Footer';
import airplane from '../assets/airplane.svg';
import DatePicker from 'react-datepicker'; // Import the DatePicker component
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for the date picker
import Swal from 'sweetalert2';

// Custom hook for debouncing
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup timeout if value or delay changes
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

function OptionSearchResult() {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [isPending, startTransition] = useTransition();
    const [selectedDate, setSelectedDate] = useState(null); // State to hold the selected departure date
    const [selectedReturnDate, setSelectedReturnDate] = useState(null); // State to hold the selected return date
    const [flightData, setFlightData] = useState([]); // State to hold flight data
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state
    const location = useLocation();
    const flightDetails = location.state?.flightDetails;
    const searchPayload = location.state?.searchPayload;
    const { payload, Details, isFlightSearch, popular, isFromPlan,destinations } = location.state || {};
    console.log("Popular ID:", popular?.id);
    console.log("LOcation:", location);
    console.log(isFromPlan, 'YESSS');
    // Get initial search parameters
    const departureDate = searchPayload?.departDate;
    const returnDate = searchPayload?.returnDate;
    const fromEntityId = searchPayload?.fromEntityId;
    const toEntityId = searchPayload?.toEntityId;
    const { adults, children } = payload || {};

    const debouncedSelectedDate = useDebounce(selectedDate, 500); // 500ms delay
    const debouncedReturnDate = useDebounce(selectedReturnDate, 500); // Change to selectedReturnDate

    // Function to search for flights based on selected dates
    const searchFlights = async (date, returnDate) => {
        setLoading(true);
        setError(null); // Reset error state
    
        try {
            const departDate = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
            const formattedReturnDate = returnDate ? returnDate.toISOString().split('T')[0] : ''; // Format return date or leave empty
    
            console.log('Searching for flights on:', departDate, 'with return date:', formattedReturnDate);
    
            const url = `https://sky-scanner3.p.rapidapi.com/flights/search-roundtrip?fromEntityId=${fromEntityId}&toEntityId=${toEntityId}&departDate=${departDate}&returnDate=${formattedReturnDate}&currency=INR&adults=${adults}&children=${children}`;
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-RapidAPI-Key': 'c114e7ca47msha584b9fb4530b5fp1fdf0bjsncfc20a0732d3', // Replace with your actual API key
                    'X-RapidAPI-Host': 'sky-scanner3.p.rapidapi.com',
                },
            };
    
            const response = await fetch(url, options);
            const data = await response.json();
            console.log('NEW Flight Details:', data);
    
            // Check if flight data exists in the response
            if (data.data && data.data.itineraries && data.data.itineraries.length > 0) {
                setFlightData(data.data.itineraries);
            } else {
                setFlightData([]); // No flights found
                Swal.fire({
                    title: "No Flights Available",
                    text: "No flights found for the selected route and dates. Please try again with different dates.",
                    icon: "info",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            console.error('Error fetching flights:', error);
            setError('Error fetching flight data. Please try again.');
            setFlightData([]); // Clear flight data on error
    
            Swal.fire({
                title: "Feature Under Maintenance",
                text: "An error occurred while fetching flight data. Please try again later.",
                icon: "error",
                confirmButtonText: "Go Back"
            }).then(() => {
                navigate(-2); // Navigate back to the previous page after the alert
            });
        } finally {
            setLoading(false);
        }
    };

    // Effect to search flights when either selectedDate or selectedReturnDate changes
    useEffect(() => {
        if (debouncedSelectedDate || debouncedReturnDate) {
            searchFlights(debouncedSelectedDate, debouncedReturnDate); // Ensure both dates are passed
        }
    }, [debouncedSelectedDate, debouncedReturnDate]);

   // Effect to search flights when either selectedDate or selectedReturnDate changes
useEffect(() => {
    if (debouncedSelectedDate || debouncedReturnDate) {
        searchFlights(debouncedSelectedDate, debouncedReturnDate); 
    }
}, [debouncedSelectedDate, debouncedReturnDate, fromEntityId, toEntityId, adults, children]);

// Set initial flight data and selected dates on component mount
useEffect(() => {
    if (flightDetails) {
        setFlightData(flightDetails.data.itineraries || []);
        const initialDate = new Date(departureDate);
        const initialReturnDate = returnDate ? new Date(returnDate) : null;
        setSelectedDate(initialDate);
        setSelectedReturnDate(initialReturnDate);
    }
}, [flightDetails, departureDate, returnDate, searchPayload]);


    

    return (
        <div
  className="relative items-center justify-center"
  style={{
    backgroundImage: `url(${bgimage})`,
    backgroundSize: "50%",
    backgroundRepeat: "repeat",
  }}
>
  <div className="relative z-10 container mx-auto px-4 sm:px-6 lg:px-8">
    <div className="bg-white md:p-8 rounded-lg shadow-lg w-full md:w-auto  mt-5 mb-5">
      <div className="flex page-head items-end justify-end">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center my-2 focus:outline-none bg-secondary rounded-md text-white px-3 py-2"
        >
          <IoIosArrowBack className="mr-1 text-white" size={15} />
          Go Back
        </button>
      </div>

      <div className="search-results-container">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center items-start">
          <div className="h-auto p-5 col-span-1">
            {/* Date Picker for Departure Date */}
            <div className="date-sort">
              <div className="text-left">
                <p className="text-text">
                  Select your departing flight from <b>{fromEntityId}</b> to{" "}
                  <b>{toEntityId}</b>
                </p>
              </div>
              <div className="dates mt-5">
                <label className="text-text font-medium">Departure Date:</label>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  minDate={new Date()} // Set minimum date to today
                  className="bg-lightgray p-2 rounded-md w-full text-center"
                />
              </div>
            </div>

            {/* Date Picker for Return Date */}
            <div className="return-date-sort mt-8">
              <div className="text-left">
                <p className="text-text">
                  Select your return flight from <b>{toEntityId}</b> to{" "}
                  <b>{fromEntityId}</b>
                </p>
              </div>

              <div className="dates mt-5">
                <label className="text-text font-medium">Return Date:</label>
                <DatePicker
                  selected={selectedReturnDate}
                  onChange={(date) => setSelectedReturnDate(date)} // Only update the return date state
                  minDate={selectedDate || new Date()} // Ensure the return date is not before the departure date
                  className="bg-lightgray p-2 rounded-md w-full text-center"
                />
              </div>
            </div>
          </div>

          <div className="border rounded-lg h-auto col-span-1 md:col-span-2">
            <div className="results p-5 overflow-y-auto h-screen">
              {loading ? (
                <p>Loading flights...</p>
              ) : error ? (
                <p className="text-red-500">{error}</p>
              ) : flightData.length > 0 ? (
                flightData.map((flight, index) => (
                  <div
                    className="result bg-lightgray p-2 rounded-md mb-2"
                    key={index}
                  >
                    {flight.legs.map((leg, legIndex) => (
                      <div
                        className="bg-white p-2 h-auto rounded-sm mb-4"
                        key={legIndex}
                      >
                        <div className="head flex justify-between">
                          <div className="option-details flex gap-1 items-center py-1">
                            <div className="logo w-8 h-8 bg-lightgray rounded-xs">
                              <img
                                src={leg.carriers.marketing[0].logoUrl}
                                alt={leg.carriers.marketing[0].name}
                                className="w-full rounded-lg"
                              />
                            </div>
                            <div className="name">
                              <p className="text-text text-sm">
                                {leg.segments[0].flightNumber} -{" "}
                                {leg.carriers.marketing[0].name}
                              </p>
                            </div>
                          </div>
                          <div className="duration">
                            <p className="text-text text-sm">
                              {leg.durationInMinutes} Minutes - {leg.stopCount}{" "}
                              Stops
                            </p>
                          </div>
                        </div>

                        <div className="body flex justify-between py-2">
                          <div className="from">
                            <p className="location text-darktext font-bold text-lg">
                              {leg.origin.name}
                            </p>
                            <p className="location text-text font-regular text-xs">
                              {new Date(leg.departure).toLocaleString()}
                            </p>
                          </div>
                          <div className="middle text-center items-center flex flex-col">
                            <div className="icon w-8 h-8">
                              <img src={airplane} alt="airplane" />
                            </div>
                            <p className="text-xs text-text">TO</p>
                          </div>
                          <div className="to">
                            <p className="location text-darktext font-bold text-lg">
                              {leg.destination.name}
                            </p>
                            <p className="location text-text font-regular text-xs">
                              {new Date(leg.arrival).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="footer flex justify-between mt-2 bg-white rounded-sm p-2">
                      <div className="option-details flex gap-1 items-center py-1">
                        <p className="text-xs text-text">
                          Price: {flight.price.formatted}
                        </p>
                      </div>
                      <button
                        className="bg-[#11A59B] text-white md:px-10 px-4 py-2 text-center"
                        onClick={() => {
                          if (isFromPlan) {
                            // Navigate to Confirm_plan with the same payload and states
                            navigate("/confirm-plan", {
                              state: {
                                payload,
                                destinations,
                                flightDetails: flight,
                                isFromFlightSearch: true,
                              },
                            });
                          } else {
                            // Navigate to package-details as before
                            navigate("/package-details", {
                              state: {
                                payload,
                                Details,
                                popular,
                                flightDetails: flight,
                                isFromFlightSearch: true,
                              },
                            });
                          }
                        }}
                      >
                        Select Flight
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                !loading && <p>No flights available for this date.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</div>

    );
}

export default OptionSearchResult;
