// src/components/HomeHeader.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from './AuthContext';
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setLogin } from "./actions"; // Import the action
import { Link } from "react-router-dom";
import CurrencySelector from "./CurrencySelector";
import CurrencySelectorHeader from "./CurrencySelectorHeader";
import avatar from '../assets/avatar.jpg';
import ProfileDrawer from "./ProfileDrawer";
import NotificationDrawer from './NotificationDrawer';
import { currencies } from "../data/currencies";
import logo from "../assets/innerlogo.png";
import menui from "../assets/menuib.png";
import notificationi from '../assets/notification.svg';
import LogoutConfirmationPopup from './LogoutConfirmation'; // Adjust the path as needed



const Header = ({ onLoginClick, onLogoutClick }) => {
  const baseUrl = "https://admin.tourismooze.com/storage/"; // Replace with your actual base URL
  const { username, userEmail, profilePic, token, setToken } = useContext(AuthContext);
  const [name, setName] = useState(() => localStorage.getItem('username') || '');
  const [profileImage, setProfileImage] = useState(() => localStorage.getItem('profilePic') || '');
  const defaultCurrency = currencies.find((currency) => currency.value === "INR");
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [profileDrawer, setProfileDrawer] = useState(false);
  const [notificationDrawer, setNotificationDrawer] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  console.log(typeof profileImage === 'string' ? `${baseUrl}${profileImage}` : avatar);

  const handleLogoutClick = () => {
    setShowLogoutPopup(true);
  };

  const handleConfirmLogout = () => {
    setShowLogoutPopup(false);
    Logout();
  };

  const handleCancelLogout = () => {
    setShowLogoutPopup(false);
  };


  const Login = useSelector((state) => state.Login);
  const dispatch = useDispatch();
  const tokenstatus = localStorage.getItem("token");

  console.log("token status", token);

  const { handleLogout } = useContext(AuthContext);
 
  // Persist username and profilePic to localStorage whenever they change
  useEffect(() => {
    if (username) {
      setName(username);
      localStorage.setItem('username', username);
    }
    if (profilePic) {
      setProfileImage(profilePic);
      localStorage.setItem('profilePic', profilePic);
    }
  }, [username, profilePic]);

  
  const Logout = async () => {
    localStorage.removeItem("token", null);
    // onLogoutClick()
    // dispatch(setLogin(false)); // Set Login state to false =====> set this when logout response success

    try {
      const response = await axios.post(
        "https://admin.tourismooze.com/api/public/logout",

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data.status;
      if (response.data.status == false) {
        setToken(null);
        onLogoutClick();
        handleLogout(token);
        dispatch(setLogin(false));
      }
      // alert(response.data.message)
    } catch (error) {
      console.error(error);
    }
  };

  console.log("user-LoggedIn", Login);

  const handleCurrencyChange = (newCurrency) => {
    setSelectedCurrency(newCurrency);
  };

  useEffect(() => {
    console.log("Selected currency:", selectedCurrency);
  }, [selectedCurrency]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);  // When the scroll is more than 50px, set state to true
      } else {
        setIsScrolled(false); // Otherwise, set it to false
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup event listener on unmount
    };
  }, []);
  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Return empty string if the input is falsy
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const limitWords = (text, limit) => {
    if (!text) return ""; // Return empty string if text is falsy
    const words = text.split(" "); // Split the text into an array of words
    if (words.length <= limit) return text; // If word count is within limit, return the original text
    return words.slice(0, limit).join(" ") + "..."; // Join the first 'limit' words and append ellipsis
  };

  return (
    <div className={`${
        isScrolled ? 'w-[100%] shadow-lg  relative bg-white z-20' : 'w-[100%] shadow-lg  relative bg-white z-20'
      }`}>
      {profileDrawer && (
        <ProfileDrawer onClose={() => setProfileDrawer(false)} />
      )}

{notificationDrawer && (
        <NotificationDrawer onClose={() => setNotificationDrawer(false)} />
      )}


      <div className="flex flex-col lg:flex-row justify-between items-center py-4 container">
        <div className="flex justify-between items-center w-full z-50">
          <div>
            <Link to="/">
              <img src={logo} alt="logo" className="w-36 lg:w-52" />
            </Link>
          </div>
          <div>
            <div className='flex justify-start items-center ' onClick={() => setProfileDrawer(true)}>
            <img  className='lg:hidden block w-5' src={menui} alt='menuicon' />
            {/* <span className="ml-2 text-white text-[12px] font-semibold lg:hidden block" >Menu</span> */}
          </div>
          </div>
          <div className="justify-center lg:justify-center items-center w-full hidden lg:flex gap-12 text-black px-3">
            <Link to="/destinations" className="mx-4 lg:mx-0 text-md">
              Explore Destinations
            </Link>
            <Link to="/packages" className="mx-4 lg:mx-0 text-md">
              Explore Packages
            </Link>
          </div>
        </div>

        <div className="flex items-center justify-between lg:justify-end w-full lg:w-[50%] z-10 font-semibold">
          <CurrencySelectorHeader selectedCurrency={selectedCurrency} setSelectedCurrency={handleCurrencyChange} />
          
          
          <div className='justify-start items-center ml-2 mr-2 cursor-pointer relative' onClick={() => setNotificationDrawer(true)}>
          <span className='absolute bg-red-400 text-white text-[10px] px-1 py-05 rounded-full ml-2 -mt-2'>01</span>
          <img
              src={notificationi}
              alt="Notification icon"
              
              className="w-5 h-5"
            />
          </div>
          
          <div className="lg:flex items-center ml-6 hidden cursor-pointer" onClick={() => setProfileDrawer(true)}>
            <img src={menui} alt="menuicon" />
            
            <span className="ml-2 text-darktext font-medium">
              Menu
            </span>
          </div>
          
          <button
        onClick={tokenstatus == null ? onLoginClick : handleLogoutClick}
        className="bg-[#11A59B] rounded-full text-white font-semibold ml-6"
      >
        <span className="font-bold text-md">
          {tokenstatus == null ? (
            <div className="px-8 py-1">
              <p className="font-poppins text-lg">Login</p>
            </div>
          ) : (
            <div className="flex gap-2 justify-start items-center pl-1 py-[1px] pr-5">
              <img
                className="w-8 h-8 rounded-full"
                src={profileImage ? `${baseUrl}${profileImage}` : avatar}
                
                alt="Profile"
              />
              <div className="text-left">
                <p className="font-poppins text-sm">{capitalizeFirstLetter(limitWords(name, 1))}</p>
                <p className="font-light text-[10px] mt-[-2px]">Logout</p>
              </div>
            </div>
          )}
        </span>
      </button>

      {/* Logout Confirmation Popup */}
      {showLogoutPopup && (
        <LogoutConfirmationPopup
          onConfirm={handleConfirmLogout}
          onCancel={handleCancelLogout}
        />
      )}


        </div>
      </div>
    </div>
  );
};

export default Header;
