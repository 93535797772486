import React, { useEffect, useState, useRef } from "react";
import bg from "../assets/BG.svg";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Footer";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import load from '../assets/loading.gif';

function DestinationDetail() {
  const baseUrl = "https://admin.tourismooze.com/storage/"; // Replace with your actual base URL
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const sliderRef = useRef(null);
  const secsliderRef = useRef(null);
  const [destinationAttractiondata, setDestinationAttractionData] = useState(
    []
  );

  const location = useLocation();
  const { destination } = location.state;

  const [sliderDataOne, setSliderDataOne] = useState([]);
  const [sliderDataTwo, setSliderDataTwo] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: `Bearer`, // Assuming you have a token from context
          "Content-Type": "application/json",
        };

        const response = await axios.post(
          `https://admin.tourismooze.com/api/public/destination/${destination.id}`,
          { headers }
        );

        const destinationData = response.data.data.destination;
        const destinationattractions =
          response.data.data.destination_attractions;

        const sliderimagesone = response.data.data.slider_1;
        const sliderimagestwo = response.data.data.slider_2;
        setSliderDataTwo(sliderimagestwo);
        setSliderDataOne(sliderimagesone);
        setData(destinationData);
        setDestinationAttractionData(destinationattractions);
        console.log("Full Data", destinationData);

        destinationData.forEach((destination, index) => {
          console.log("Heading:", destination.destination_heading);
        });
        destinationattractions.forEach((attraction, index) => {
          console.log("Attraction name:", attraction.name);
        });
      } catch (error) {
        setError(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [destination.id]);

  const fetchnear = async (destination) => {
    try {
      const headers = {
        Authorization: `Bearer`, // Assuming you have a token from context
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        `https://admin.tourismooze.com/api/public/destination/${destination.id}`,
        { headers }
      );

      const destinationData = response.data.data.destination;
      const sliderimagesone = response.data.data.slider_1;
      const sliderimagestwo = response.data.data.slider_2;
      setSliderDataTwo(sliderimagestwo);
      setSliderDataOne(sliderimagesone);
      setData(destinationData);
      console.log("Full Data", destinationData);

      destinationData.forEach((destination, index) => {
        console.log("Heading:", destination.destination_heading);
      });
    } catch (error) {
      setError(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    appendDots: (dots) => (
      <div
        className="grid grid-cols-1 lg:grid-cols-2 items-center gap-y-0 lg:gap-y-2 px-0 lg:px-12"
        style={{ bottom: "30px", paddingLeft: "20px", paddingRight: "20px" }}
      >
        <ul className="flex justify-center xl:justify-start space-x-1 lg:m-0">
          {dots}
        </ul>
        <div className="text-white flex justify-center items-center space-x-2 xl:-mt-8 m-2">
          <div className="backdrop-blur-sm bg-white/30 flex justify-center items-center space-x-2 px-5 lg:py-2 py-1 rounded-full">
            <button
              onClick={() => sliderRef.current.slickPrev()}
              className="bg-transparent border-none flex items-center space-x-1 text-sm"
            >
              <span>&#x2190;</span> <span>PREV</span>
            </button>
            <span className="text-white">|</span>
            <button
              onClick={() => sliderRef.current.slickNext()}
              className="bg-transparent border-none flex items-center space-x-1 text-sm"
            >
              <span>NEXT</span> <span>&#x2192;</span>
            </button>
          </div>
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const secondslidesettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    appendDots: (dots) => (
      <div
        className="grid grid-cols-1 lg:grid-cols-2 items-center gap-y-0 lg:gap-y-2 px-0 lg:px-12"
        style={{ bottom: "30px", paddingLeft: "20px", paddingRight: "20px" }}
      >
        <ul className="lg:hidden flex justify-center xl:justify-start space-x-1 lg:m-0">
          {dots}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (loading) {
    return <div className="text-primary flex items-center justify-center h-screen">
      <div className='loading w-20 h-20 mx-auto my-auto'>
        <img src={load} ></img>
      </div>
    </div>;
  }

  return (
    <div
      style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'repeat', backgroundSize: '50%'  }}
      className="bg-cover bg-center bg-no-repeat z-0 absolute w-full"
    >
       <div className="container mt-5 mb-5">
       <button
          onClick={() => navigate(-1)}
          className="flex items-center  focus:outline-none bg-[#1E75BB] rounded-md text-white font-poppins px-2 py-1 mb-2"
        >
          <IoIosArrowBack className=" text-white" size={18} />
          Go Back
        </button>
       

      <div className="w-full rounded-lg  bg-white border border-[#ddd] p-5">
       

        {/* slider section */}
        {loading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}
        {!loading && !error && (
          <Slider ref={sliderRef} {...settings}>
            {sliderDataOne.map((sliderimages, index) => (
              <div
                key={index}
                className="relative dis mb-4 w-full rounded-lg overflow-hidden shadow-lg overflow-x-clip"
              >
                <img
                  src={baseUrl + sliderimages.image_path}
                  alt={destination.destination_name}
                  className="w-full lg:h-96 h-72 object-cover overflow-x-clip"
                />
              </div>
            ))}
          </Slider>
        )}
        {/* end slider section */}

        {data.map((destination, index) => (
          <div key={index}>
            <p className="font-poppins text-[#006464] font-bold md:text-2xl text-2xl md:text-left text-center p-2">
              {destination.destination_name}
            </p>
            <div
              className="text-text font-poppins md:text-md text-md p-2 md:text-left text-center "
              dangerouslySetInnerHTML={{ __html: destination.description_1 }}
            />
             {/* second slider section */}
        {loading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}
        {!loading && !error && (
          <Slider ref={secsliderRef} {...secondslidesettings}>
            {sliderDataTwo.map((sliderimages, index) => (
              <div
                key={index}
                className="relative dis mb-4  mt-4 w-full rounded-lg overflow-hidden shadow-lg overflow-x-clip"
              >
                <img
                  src={baseUrl + sliderimages.image_path}
                  alt={destination.destination_name}
                  className="w-full lg:h-96 h-72 object-cover overflow-x-clip"
                />
              </div>
            ))}
          </Slider>
        )}
        {/* end second slider section */}

            <div
              className="font-poppins text-text text-md md:text-md p-2 md:text-left text-center"
              dangerouslySetInnerHTML={{ __html: destination.description_2 }}
            />
            <br />
            <br />
          </div>
        ))}

       

        {/* image grid info section */}
       
        {/* end grid info section */}

        {/* attractions section */}
        {destinationAttractiondata.length > 0 && (
          <div className="mt-14">
            <p className="font-poppins my-6 text-[#11A59B] font-semibold text-semibold">
              Attractions
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {destinationAttractiondata.map((attraction, index) => (
                <div
                  key={index}
                  className="relative cursor-pointer rounded-xl overflow-hidden"
                >
                  <div className="w-full h-60 overflow-hidden">
                    <img
                      src={`${baseUrl}${attraction.image}`}
                      alt={attraction.name}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black via-black/60 to-black/0 p-4">
                      <p
                        className="capitalize text-white font-bold mb-1"
                        dangerouslySetInnerHTML={{ __html: attraction.name }}
                      />
                      <p
                        className="text-sm font-light text-white overflow-y-scroll custom-scrollbar"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                          scrollBehavior: "smooth",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: attraction.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* end attractions section */}
        </div>
        <div className="text-head">
        <p className="font-poppins text-primary my-6 font-semibold text-semibold">
          Nearby Destinations
        </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
        
          {data.map((destination, index) => (
            <div
              key={index}
              className="border-[1px] border-primary rounded-xl p-2 gap-4 cursor-pointer flex flex-row h-60 bg-white "
            >
              <div className="w-full  overflow-hidden rounded-xl basis-2/5">
                <img
                  src={`${baseUrl}${destination.destination_image}`}
                  alt={destination.destination_name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex flex-col flex-grow  basis-3/5 ">
                <p
                  className="text-left font-poppins text-secondary font-bold"
                  dangerouslySetInnerHTML={{
                    __html: destination.destination_name,
                  }}
                />
                <p
                  className="font-poppins my-2 text-[1rem] flex-grow overflow-y-scroll custom-scrollbar scrollbar-hide"
                  style={{
                    //display: "-webkit-box",
                    //WebkitLineClamp: 3,
                    //WebkitBoxOrient: "vertical",
                    //overflowY: "auto", // Ensures scrolling
                    //scrollbarWidth: "thin", // For Firefox
                    //scrollbarColor: "#11A59B",
                    //scrollBehavior: "smooth", // Smooth scrolling
                  }}
                  dangerouslySetInnerHTML={{
                    __html: destination.description_2,
                  }}
                />
                <button
                  className="font-poppins mt-auto text-base flex gap-2 items-center text-secondary"
                  onClick={() => fetchnear(destination)}
                >
                  View
                  <ArrowRight size={18} />
                </button>
              </div>
            </div>
          ))}
        </div>
      
      </div>




      {/* footer section */}
      <Footer />
      {/* end footer section */}
    </div>
  );
}

export default DestinationDetail;
