import React, { useEffect, useState } from 'react';
import BG2 from '../assets/BG2.svg';
import { AuthContext } from './AuthContext';
import { useContext } from 'react';
import axios from 'axios';
import vector from '../assets/Vector.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles for the date picker
import { FaCalendarAlt } from 'react-icons/fa'; // Optional: Importing a calendar icon for better UI
import { useNavigate } from 'react-router-dom';
import Footer from './Footer'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useLocation } from 'react-router-dom';
import airplane from '../assets/airplane.svg';
import train from '../assets/train.svg';
import irctc from '../assets/irctc-logo.png';

function ConfirmPlan({onLoginClick,onLogoutClick}) {
  const { token } = useContext(AuthContext);
  const [destinations, setDestinations] = useState([]);
  
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSugession, setSelectedSugession] = useState('');
  const [counter, setCounter] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedadultcount, setSelectedAdultCount] = useState(1);
  const [childrencount, setChildrenCount] = useState(0);
  const [roomcount, setRoomCount] = useState(1);
  const [countryId,setCountryId] = useState('');
  const [loader,setLoader] = useState(false)
  const baseUrl = 'https://admin.tourismooze.com/storage/';
  const MySwal = withReactContent(Swal);
  const location = useLocation();
  const {payload} = location.state || {}; // Access the passed data
  const { isFromFlightSearch, flightDetails, trainDetails, isFromTrainSearch } = location.state || {};
  const {adults, children, rooms, duration,booking_date,country,Destinations  } = payload || {};

  
  const initialAdultCount = (isFromFlightSearch || isFromTrainSearch) ? adults || 0 : 0;
 // Set initial count based on payload if isFromFlightSearch
  const initialChildrenCount = (isFromFlightSearch || isFromTrainSearch) ? children || 0 : 0; // Set initial count based on payload if isFromFlightSearch
  const initialRoomCount = (isFromFlightSearch || isFromTrainSearch) ? rooms || 0 : 0; // Set initial count based on payload if isFromFlightSearch
  const initialduration = (isFromFlightSearch || isFromTrainSearch) ? duration || 0 : 0; 
  const initialbooking_date = (isFromFlightSearch || isFromTrainSearch) ? booking_date : null;
  const initialcountry = (isFromFlightSearch || isFromTrainSearch) ? country : null;
  const initialDestinations = (isFromFlightSearch || isFromTrainSearch) ? Destinations: null;
  const numberOfLegs = isFromFlightSearch ? flightDetails.legs.length : 0; // Set default to 0 if not a flight search

  console.log("Destinations:", destinations);
  console.log("Flight Details2:", flightDetails);
  
  // Extract `destinations` from location.state
  const { destinations: destinations2 } = location.state || {}; // Use a different name to avoid conflicts
  console.log(destinations2, 'Updated Destinations');
    // State to hold the selected children count
    const [childrencount2, setChildrenCount2] = useState(initialChildrenCount);
    // State to hold the selected adult count
    const [selectedadultcount2, setSelectedAdultCount2] = useState(initialAdultCount);
    const [roomcount2, setRoomCount2] = useState(initialRoomCount);
    const [duration2, setduration2] = useState(initialduration);
    const [booking_date2, setbooking_date2] = useState(initialbooking_date);
    const [country2, setcountry2] = useState(initialcountry);
    const [Destinations2, setDestinations2] = useState(initialDestinations);

    
  const [travelOption, setTravelOption] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    const { destinations: destinations2 } = location.state || {};
    if (isFromTrainSearch && destinations2) {
      setDestinations(destinations2);
    }
  }, [location.state, isFromTrainSearch]);



  useEffect(() => {
    const { destinations: destinations2 } = location.state || {};
    if (isFromFlightSearch && destinations2) {
      setDestinations(destinations2);
    }
  }, [location.state, isFromTrainSearch]);

  useEffect(() => {
    if (!isFromTrainSearch && !isFromFlightSearch) {
      fetchSelectedDestinations();
    }
  }, [isFromTrainSearch, isFromFlightSearch]);

  
  // Function for fetching selected destinations
  const fetchSelectedDestinations = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const response = await axios.post(`https://admin.tourismooze.com/api/public/selected`, {}, { headers });

      // Access the correct path of selectedDestinations in the response
      const destinationData = response.data.data?.selectedDestinations || []; // Fallback to empty array if undefined
      setDestinations(destinationData); // Set the correct data
      console.log(destinationData, '*********');
      
    } catch (error) {
      console.error("Error fetching selected destinations:", error);
    }
  };


  const handleBookingOptionChange = (event) => {
    // Optional: Prevent default behavior if needed
    // event.preventDefault(); 

    const selectedValue = event.target.value;
    setTravelOption(selectedValue);
    console.log('Selected option:', selectedValue);

    // Optional: Additional logic based on the selected value
    if (selectedValue === 'flight') {
        // Do something specific for flight option
    } else if (selectedValue === 'train') {
        // Do something specific for train option
    }
};


  const handleRemove = async (index,id)=>{
    try {
        const response = await axios.post(
          `https://admin.tourismooze.com/api/public/selected/remove/${id}`,
          {
            payload:{
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(response.data.status)
        if(response.data.status == true){
            fetchSelectedDestinations()
        }
      } catch (error) {
        console.error('Error toggling favorite:', error);
      }
}


const handleInputChange = async event => {
  const value = event.target.value;
  setQuery(value);
  if (value) {
    fetchSuggestions(value);
  } else {
    setSuggestions([]);
  }
};



const fetchSuggestions = async (value) => {
  try {
    const response = await axios.post(
      'https://admin.tourismooze.com/api/public/countries',
      { search: value},
      { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
    );
    const data = response.data;
    const suggestions = data.map(item => item);

    const countryid = data.map(item => item.id);
    setSuggestions(suggestions);
    setCountryId(countryid);
  } catch (error) {
    console.error('Error fetching suggestions:', error);
  }
};

const handleSuggestionClick = (id,countryname) => {
  setQuery(countryname);
  setSuggestions([]);
  setSelectedSugession(id);
  setCountryId()
};



const handleNUmberChange = (e) => {
  const newValue = e.target.value;
  setCounter(newValue);  // Update the counter value
  console.log('Counter value:', newValue);  // Log to console
};




const handleDateChange = (date) => {
  const formattedDate = date.toISOString().split('T')[0]; // Format to 'YYYY-MM-DD' and exclude time
  setSelectedDate(formattedDate); // Update the selected date in the state
  console.log('Selected date:', formattedDate); // Log the formatted date
};



  const increaseAdult = () => {
    setSelectedAdultCount(prev => {
      const newCount = prev + 1;
     
      

      return newCount;
    });
  };
  
  const decreaseAdult = () => {
    setSelectedAdultCount(prev => {
      const newCount = Math.max(1, prev - 1);
      
      return newCount;
    });
  };



  const increaseChildrenCount = () => {
    setChildrenCount(prev => {
      const newCount = prev + 1;
      return newCount;
    });
  };
  
  const decreaseChildrenCount = () => {
    setChildrenCount(prev => {
      const newCount = Math.max(0, prev - 1);
      
      return newCount;
    });
  };


  const increaseRoomCount = () => {
    setRoomCount(prev => {
      const newCount = prev + 1; 
      return newCount;
    });
  };
  
  const decreaseRoomCount = () => {
    setRoomCount(prev => {
      const newCount = Math.max(1, prev - 1);
      return newCount;
    });
  };






// submitting plan
const submitPlan = async () => {
  console.log(selectedSugession, '````````````');
  setLoader(true);

  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };




const payload = {
  adults: selectedadultcount,
  children: childrencount,
  rooms: roomcount,
  duration: counter,
  booking_date: selectedDate, // Ensure this is in 'YYYY-MM-DD' format
  country: selectedSugession,
};

  try {
    const response = await axios.post(
      'https://admin.tourismooze.com/api/public/enquiry',
      payload, 
      { headers }
    );

    console.log(response.data.status); // Log the response data

    if (response.data.status === true) {
      // Clear all relevant state
      setChildrenCount('');
      
      // Navigate based on travel option

    if (travelOption === 'flight') {
        // Prepare state for flight search navigation
        navigate('/flight-search', { state: { payload, destinations, isFromPlan: true,  } });
    } else if (travelOption === 'train') {
        // Prepare state for train search navigation
        navigate('/train-search', { state: { payload, destinations, isFromPlan: true, } });
    } 
     else {
      setCounter('');
      setCountryId('');
      setDestinations('');
      setQuery('');
      setRoomCount('');
      setLoader(false);

        navigate('/plan-success');
      }
    }

    // Handle any errors returned from the API
    if (response.data.errors) {
      for (const error of response.data.errors.country) {
        MySwal.fire({
          title: <p className='text-[18px] text-red-500 font-poppins'>{error}</p>,
          footer: 'Tourismooze',
        });
      }
    }
  } catch (error) {
    console.error('Error submitting plan:', error); // Log the error for debugging
    MySwal.fire({
      title: <p className='text-[18px] text-red-500 font-poppins'>An error occurred. Please try again.</p>,
      footer: 'Tourismooze',
    });
  } finally {
    setLoader(false); // Ensure loader is turned off in both success and error cases
  }
};


// end submitting plan



















  // JSX Return
  return (
    <div className='h-auto' style={{ backgroundImage: `url(${BG2})`, backgroundSize: 'cover' }}>
      <div className='w-full h-24 bg-[#AEF1EC] flex items-center rounded-b-2xl'>
        <div className='md:w-[80%] w-full mx-auto justify-center'>
          <p className='font-poppins text-2xl text-[#0FA499] font-bold'>Confirm Your Plan</p>
        </div>
      </div>

      <div className='h-24'></div>

      <div className='md:w-[85%] w-full mx-auto'>
        <div className='flex flex-wrap xl:flex-nowrap gap-5 justify-between'>
          <div className='w-full'>
          <label className='font-poppins text-white font-semibold text-lg'>Departure date</label> <br />
      <div className='relative backdrop:blur-lg flex items-center my-4 bg-white/10 h-12 rounded-full w-full px-5'>
        {/* DatePicker input */}
        <DatePicker
        required
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy" // Customize the date format
          placeholderText='Select a date'
          minDate={new Date()} // Disable past dates
          className='bg-transparent outline-none cursor-pointer text-[#FFFFFF] placeholder:text-[#FFFFFF] font-poppins w-full' // Style it to blend with the design
        />
        {/* Optional: Add a calendar icon for better UX */}
        <FaCalendarAlt className="absolute right-4 text-[#FFFFFF] cursor-pointer" />
      </div>
            <label className='font-poppins text-white font-semibold text-lg'>Departure Country</label> <br />
            <div className='backdrop:blur-lg flex items-center my-4 bg-white/10 h-12 rounded-full w-full'>
              <input required type="text" value={query} onChange={handleInputChange} className='bg-transparent outline-none cursor-pointer text-[#FFFFFF] placeholder:text-[#FFFFFF] font-poppins ml-5' placeholder='Search' />
            </div>
            {suggestions.length > 0 && (
             <div className='h-56 overflow-y-auto bg-[#AEF1EC] border-none rounded-sm relative'>
             <ul className="border border-gray-300 mt-2 rounded shadow-lg">
               {suggestions.map((suggestion, index) => (
                 <li
                   key={index}
                   onClick={() => handleSuggestionClick(suggestion.id,suggestion.countryname)}
                   className="p-2 cursor-pointer hover:bg-gray-200"
                 >
                   {suggestion.countryname}
                   
                 </li>
                 
               ))}
             </ul>
           
             {/* Custom Scrollbar Styling */}
             <style jsx>{`
               /* Hide scrollbar for Chrome, Safari and Opera */
               .h-56::-webkit-scrollbar {
                 display: none;
               }
           
               /* Hide scrollbar for IE, Edge and Firefox */
               .h-56 {
                 -ms-overflow-style: none;  /* IE and Edge */
                 scrollbar-width: none;  /* Firefox */
               }
             `}</style>
           </div>
           
  )}
            <label className='font-poppins text-white font-semibold text-lg'>Duration of your trip</label> <br />
            <div className='backdrop:blur-lg flex items-center my-4 bg-white/10 h-12 rounded-full w-full'>
            <input required min={1}
        type='number'
        value={counter}  // Bind the input to the counter state
        onChange={handleNUmberChange}  // Event handler for input change
        className='bg-transparent outline-none cursor-pointer w-full text-[#FFFFFF] placeholder:text-[#FFFFFF] font-poppins ml-5 mr-5'
        placeholder='Select duration'
      />
            </div>



            <div className=''>
            {isFromFlightSearch && (
             
<div className='mt-4'>
<div className='mb-4'>
<p className="font-bold text-left p-2 text-white">Arrival - Return</p>
</div>
  <div className='result relative bg-lightgray p-2 rounded-md mb-2 pt-6'>
    <div className="absolute -top-3 left-0 w-full flex justify-left gap-3 -mb-3 mx-4">
      <p className="bg-primary text-xs font-normal text-white py-1 px-4 rounded-xl">Flights # {flightDetails.legs.length}</p>
      <p className="bg-primary text-xs font-normal text-white py-1 px-4 rounded-xl">{flightDetails.legs.length === 1 ? 'One Way' : 'Round Trip'}</p>
    </div>
    {flightDetails.legs.map((leg, legIndex) => (
    <div className='bg-white p-2 h-auto rounded-sm mt-1'  key={legIndex}>
      <div className='head flex justify-between'>
        <div className='option-details flex gap-1 items-center py-1'>
          <div className='logo w-8 h-8 bg-lightgray rounded-xs'>
            <img src={leg.carriers.marketing[0].logoUrl} alt={leg.carriers.marketing[0].name} className='w-full rounded-lg' />
          </div>
          <div className='name'>
            <p className='text-text text-sm'>{leg.segments[0].flightNumber} - {leg.carriers.marketing[0].name}</p>
          </div>
        </div>
        <div className='duration'>
          <p className='text-text text-sm'>{leg.durationInMinutes} Minutes - {leg.stopCount} Stops</p>
        </div>
      </div>
      <div className='body flex justify-between py-2'>
        <div className='from'>
          <p className='location text-darktext font-bold text-lg'>{leg.origin.name}</p>
          <p className='location text-text font-regular text-xs'>{leg.departure}</p>
        </div>
        <div className='middle text-center items-center flex flex-col'>
          <div className='icon w-8 h-8'><img src={airplane} alt='airplan' /></div>
          <p className='text-xs text-text'>TO</p>
        </div>
        <div className='to'>
          <p className='location text-darktext font-bold text-lg'>{leg.destination.name}</p>
          <p className='location text-text font-regular text-xs'>{leg.arrival}</p>
        </div>
      </div>
      
    </div>
    ))}
    <div className='footer flex justify-between  bg-white rounded-sm p-2 mt-2'>
        <div className='option-details flex gap-1 items-center py-1'>
          <div className='icon w-5 h-5 bg-lightgray rounded-xs'>
            <img src="" alt="" />
          </div>
          <div className='name'>
            <p className='text-text text-lg'>{flightDetails.price.formatted}</p>
          </div>
        </div>
      </div>
  </div>
</div>
)}

{isFromTrainSearch && (

<div className='mt-4'>
<div className='mb-4'>
<p className="font-bold text-left p-2 text-white">Arrival - Return</p>
</div>
<div className='mt-2'>

  <div className='result relative bg-lightgray p-2 rounded-md mb-2 pt-6'>
    <div className="absolute -top-3 left-0 w-full flex justify-left gap-3 -mb-3 mx-4">
      <p className="bg-primary text-xs font-normal text-white py-1 px-4 rounded-xl">Trains # 1</p>
      <p className="bg-primary text-xs font-normal text-white py-1 px-4 rounded-xl">One Way</p>
    </div>
    
      <div
        className="result bg-lightgray p-2 rounded-md mb-2"
        
      >
        <div className="bg-white p-2 h-auto rounded-sm">
          <div className="head flex justify-between">
            <div className="option-details flex gap-1 items-center py-1">
              <div className="logo w-8 h-8 bg-lightgray rounded-sm">
                <img
                  src={irctc}
                  alt="Indian Railway"
                  className="w-full rounded-lg"
                />
              </div>
              <div className="name">
                <p className="text-text text-sm">
                  {trainDetails.train_number} -{" "}
                  {trainDetails.train_name}
                </p>
              </div>
            </div>
            <div className="duration">
              <p className="text-text text-sm">
                {trainDetails.duration} Hours - {trainDetails.distance} KM Distance

              </p>
            </div>
          </div>

          <div className="body flex justify-between py-2">
            <div className="from">
              <p className="location text-darktext font-bold text-lg">
                {trainDetails.from_station_name}
              </p>
              <p className="location text-text font-regular text-sm">
                {trainDetails.train_date}
              </p>
            </div>
            <div className="middle text-center items-center flex flex-col">
              <div className="icon w-8 h-8">
                <img src={train} alt="Train" />
              </div>
              <p className="text-sm text-text">TO</p>
            </div>
            <div className="to">
              <p className="location text-darktext font-bold text-lg">
                {trainDetails.to_station_name}
              </p>
              <p className="location text-text font-regular text-sm">
              {trainDetails.train_date}
              </p>
            </div>
          </div>
          <div className="footer flex justify-start">
            <div className="option-details flex gap-1 items-center py-1">
            
            <p className="text-sm text-text">
            Selected: 
              </p>
              <span className="text-sm text-text border p-2 mr-2">
               Class <b>{trainDetails.fareDetails.class_name}</b>
              </span>
              <span className="text-sm text-text  border p-2 mr-2">
                Type <b>{trainDetails.fareDetails.class_type}</b>
              </span>
              <span className="text-md text-text  border p-2 bg-lightgray">
                Fare <b>₹ {trainDetails.fareDetails.fare}</b>
              </span>
            </div>
            
          </div>
        </div>
      </div>
    
    
  </div>
</div>
</div>
)}


            </div>
          </div>

          {/* Selected Destinations */}
          <div className='w-full'>
            <p className='font-poppins text-white font-semibold text-lg'>Selected Destinations</p>
            <div>
              {destinations.length > 0 ? (
                destinations.map((destination, index) => (
                  <div key={index} className='bg-white rounded-md pb-6 p-3 m-2 shadow'>
                    <div className='flex flex-wrap md:flex-nowrap gap-4 relative'>
                    <img 
                      src={baseUrl+destination.destination_image} 
                      alt={destination.destination_name} 
                      className='w-36 object-cover mt-2 rounded-md'
                    />
                    <div>
                    <p className='font-poppins font-semibold text-md text-[#006464]'>{destination.destination_name}</p>
                    <p className='text-xs text-gray-600 font-poppins pb-4'>{destination.destination_heading}</p>
                   
                    <div className='flex justify-end gap-4 items-center absolute right-1 w-full' onClick={() => handleRemove(index,destination.id)} >
                        <button className='items-center text-xs grid grid-flow-col gap-1' >REMOVE
                        <img src={vector} alt='minus' className='w-5 h-5' /></button>
                    </div>

                    </div>
                    
                    </div>
                  </div>
                ))
              ) : (
                <p onClick={()=>navigate('/explore-destination')}>No destinations selected.</p>
              )}
            </div>
          </div>

          <div className='w-full'>

                <div className='bg-white rounded-lg p-6 relative xl:-mt-36'>
                  <p className='font-poppins text-xl text-[#1E75BB] font-semibold mb-2 p-4'>Choose No of Travellers</p>



<div className='bg-[#1E75BB] p-4 rounded-t-2xl'>
<div className='flex flex-wrap justify-between items-center mb-12'>
    <div className='font-poppins text-[white] text-[14px]'>Adults</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreaseAdult} className='border-[1px] text-[14px] text-white border-white px-2 font-poppins rounded-xl'>-</button>
      </div>
      {!isFromFlightSearch && !isFromTrainSearch && <div className='text-center text-white font-poppins'>{selectedadultcount}</div>}
      {isFromFlightSearch || isFromTrainSearch && <div className='text-center text-white font-poppins'>{selectedadultcount2}</div>}
      <div>
        <button onClick={increaseAdult}  className='border-[1px] text-[14px] text-white border-white px-2 font-poppins rounded-xl'>+</button>
      </div>
    </div>
  </div>

  <div className='flex flex-wrap justify-between items-center mb-12'>
    <div className='font-poppins text-[white] text-[14px]'>Children's (0 to 14years)</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreaseChildrenCount} className='border-[1px] text-[14px] text-white border-white px-2 font-poppins rounded-xl'>-</button>
      </div>
      {!isFromFlightSearch && !isFromTrainSearch && <div className='text-center text-white font-poppins'>{childrencount}</div>}
       {isFromFlightSearch || isFromTrainSearch && <div className='text-center text-white font-poppins'>{childrencount2}</div>}
      <div>
        <button onClick={increaseChildrenCount}  className='border-[1px] text-[14px] px-2 border-white text-white font-poppins rounded-xl'>+</button>
      </div>
    </div>
  </div>

  <div className='flex flex-wrap justify-between items-center mb-2'>
    <div className='font-poppins text-[white] text-[14px]'>Rooms</div>
    <div className='grid grid-cols-3 gap-2'>
      <div>
        <button onClick={decreaseRoomCount} className='border-[1px] text-[14px] text-white border-white px-2 font-poppins rounded-xl'>-</button>
      </div>
      {!isFromFlightSearch && !isFromTrainSearch && <div className='text-center text-white font-poppins'>{roomcount}</div>}
      {isFromFlightSearch || isFromTrainSearch && <div className='text-center text-white font-poppins'>{roomcount2}</div>}
      <div>
        <button onClick={increaseRoomCount}  className='border-[1px] text-[14px] text-white border-white px-2 font-poppins rounded-xl'>+</button>
      </div>
    </div>
  </div>

  
</div>
<div className='grid grid-cols-3 sm:grid-cols-2 xl:grid-cols-3 justify-start w-[100%] px-4 bg-lightgray p-2'>
 
                  {!isFromFlightSearch && !isFromTrainSearch && <div className='text-[#1E75BB] font-bold font-poppins'>Rooms -  {roomcount}</div>}
                  {!isFromFlightSearch && !isFromTrainSearch &&  <div className='text-[#1E75BB]  font-poppins'>{selectedadultcount} Adults , </div>}
                  {!isFromFlightSearch && !isFromTrainSearch &&  <div className='text-[#1E75BB]  font-poppins'>{childrencount} child</div>}
                    {isFromFlightSearch || isFromTrainSearch && <div className='text-[#1E75BB] font-bold font-poppins'>Rooms -  {roomcount2}</div>}
                    {isFromFlightSearch || isFromTrainSearch && <div className='text-[#1E75BB]  font-poppins'>{selectedadultcount2} Adults , </div>}
                    {isFromFlightSearch || isFromTrainSearch && <div className='text-[#1E75BB]  font-poppins'>{childrencount2} child</div>}
                    

                  </div>
<div className='mt-5'>
                    <p className='text-center font-poppins text-sm'>
                    Would you like to arrange your <br /> travel with this package ?
                  </p>
                  
                  <div className='flex gap-10 mx-auto w-full justify-center text-center font-poppins rounded-lg p-2'>
                        <div className='flex gap-2 items-center'>


                          <input
                            id='flight'
                            className='appearance-none h-4 w-4 border-2 border-primary rounded-full checked:bg-[#11A59B] checked:border-transparent focus:outline-none'
                            type='radio'
                            name='travelOption'
                            value='flight'
                            checked={isFromFlightSearch}
                            onChange={handleBookingOptionChange}
                          />
                          <label htmlFor='flight' className='text-[#11A59B] font-medium text-sm text-left'>
                            Book with <br /> Flight
                          </label>
                        </div>
                        <div className='flex gap-2 items-center'>
                          <input
                            id='train'
                            className='appearance-none h-4 w-4 border-2 border-primary rounded-full checked:bg-[#11A59B] checked:border-transparent focus:outline-none'
                            type='radio'
                            name='travelOption'
                            value='train'
                            checked={isFromTrainSearch}
                            onChange={handleBookingOptionChange}
                          />
                          <label htmlFor='train' className='text-[#11A59B] font-medium text-sm text-left'>
                            Book with <br /> Train
                          </label>
                        </div>
                      </div>


                    </div>


<div className='mt-2'>
<button 
  onClick={submitPlan} 
  className='bg-[#1E75BB] rounded-full px-20 py-3 text-white text-center mx-auto justify-center font-poppins flex items-center'
>

  {
  loader === true 
    ? 'loading...' 
    : travelOption === 'flight' 
    ? 'SEARCH FOR FLIGHTS' 
    : travelOption === 'train' 
    ? 'SEARCH FOR TRAIN' 
    : 'REQUEST BOOKING'
}
    
</button>
</div>

  
    </div>

          </div>
        </div>

      </div>


<div className='h-36'></div>
<Footer />
  </div>      
  );
}

export default ConfirmPlan;
