import React, { useContext, useEffect, useState, useTransition } from 'react';
import { AuthContext } from './AuthContext';
import bgimage from "../assets/BG.svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { IoIosArrowBack } from "react-icons/io";
import Footer from './Footer';
import airplane from '../assets/airplane.svg';
import Swal from 'sweetalert2';
import load from '../assets/loading.gif';

function OptionSearchResult() {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [isPending, startTransition] = useTransition();
    const [selectedDate, setSelectedDate] = useState(null); // State to hold the selected date
    const [flightData, setFlightData] = useState([]); // State to hold flight data
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state
    const location = useLocation();
    const flightDetails = location.state?.flightDetails; // Access the passed flight data
    const searchPayload = location.state?.searchPayload; // The payload you sent
    const {payload, Details, isFlightSearch, popular, isFromPlan, destinations } = location.state || {}; // Access the passed data
    console.log("Popular ID:", popular?.id);
    console.log("Payload:", payload);
    console.log("Booking Details:", Details);
    console.log("Flight Details:", flightData);
    console.log("LOcation:", location);
    console.log(isFromPlan, 'YESSS');
    // Get the departure date from the search payload
    const departureDate = searchPayload?.departDate;
    const fromEntityId = searchPayload?.fromEntityId;
    const toEntityId = searchPayload?.toEntityId;
    const { adults, children, booking_date, rooms, package_id } = payload || {};
        
    // Function to get the next 5 days including the departure date
    const getNextFiveDays = (startDate) => {
        const dates = [];
        const baseDate = new Date(startDate);
        for (let i = 0; i < 5; i++) {
            const nextDate = new Date(baseDate);
            nextDate.setDate(baseDate.getDate() + i);
            dates.push(nextDate);
        }
        return dates;
    };

    const nextFiveDays = getNextFiveDays(departureDate);

    // Function to search for flights based on selected date
    const searchFlights = async (date) => {
        setLoading(true);
        setError(null); // Reset error state
    
        try {
            const departDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
            console.log('Searching for flights on:', departDate); // Debugging line
    
            const url = `https://sky-scanner3.p.rapidapi.com/flights/search-one-way?fromEntityId=${fromEntityId}&toEntityId=${toEntityId}&departDate=${departDate}&currency=INR&adults=${adults}&children=${children}`;
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-RapidAPI-Key': '7028f071admsh10de74e6a22b35dp188174jsn4dcc22eb9769', // Replace with your actual API key
                    'X-RapidAPI-Host': 'sky-scanner3.p.rapidapi.com',
                },
            };
    
            const response = await fetch(url, options);
            const data = await response.json();
            console.log('NEW Flight Details:', data);
    
            // Check if flight data exists in the response
            if (data.data && data.data.itineraries && data.data.itineraries.length > 0) {
                setFlightData(data.data.itineraries);
            } else {
                setFlightData([]); // Clear flight data if no flights found
                Swal.fire({
                    title: "No Flights Available",
                    text: "No flights found for the selected date. Please try another date.",
                    icon: "info",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            console.error('Error fetching flights:', error);
            setError('Error fetching flight data. Please try again.');
            setFlightData([]); // Clear flight data on error
    
            Swal.fire({
                title: "Feature Under Maintenance",
                text: "An error occurred while fetching flight data. Please try again later.",
                icon: "error",
                confirmButtonText: "Go Back"
            }).then(() => {
                navigate(-2); // Navigate back to the previous page after the alert
            });
        } finally {
            setLoading(false);
        }
    };

    // Set initial flight data and selected date on component mount
    useEffect(() => {
        if (flightDetails) {
            console.log('Initial flight details:', flightDetails); // Debugging line
            setFlightData(flightDetails.data.itineraries || []); // Set initial flight data
            const initialDate = new Date(departureDate); // Set initial date from departure date
            setSelectedDate(initialDate); // Initialize with the departure date
            searchFlights(initialDate); // Fetch flights for the initial date
        }
    }, [flightDetails, departureDate]);

    // Effect to fetch flights when a new date is selected
    useEffect(() => {
        if (selectedDate) {
            searchFlights(selectedDate);
        }
    }, [selectedDate]);

    return (
        <div
        className="relative items-center justify-center"
        style={{
          backgroundImage: `url(${bgimage})`,
          backgroundSize: "50%",
          backgroundRepeat: "repeat",
        }}
      >
        <div className="relative z-10 container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white lg:p-8 md:p-5 sm:p-2 rounded-lg shadow-lg w-full md:w-auto  mt-5 mb-5">
            <div className="flex page-head items-end justify-end">
              <button
                onClick={() => navigate(-1)}
                className="flex items-center my-2 focus:outline-none bg-secondary rounded-md text-white px-3 py-2"
              >
                <IoIosArrowBack className="mr-1 text-white" size={15} />
                Go Back
              </button>
            </div>
      
            <div className="search-results-container">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center items-start">
                {/* Date Sort Column */}
                <div className="h-auto p-5 col-span-1">
                  <div className="date-sort">
                    <div className="text-left">
                      <p className="text-text">
                        Select your departing flight from <b>{fromEntityId}</b> to{" "}
                        <b>{toEntityId}</b> on{" "}
                        <b>
                          {selectedDate
                            ? selectedDate.toLocaleDateString("en-US", {
                                weekday: "long",
                                month: "long",
                                day: "numeric",
                              })
                            : ""}
                        </b>
                      </p>
                    </div>
                    {nextFiveDays.map((date, index) => (
                      <div className="dates mt-5" key={index}>
                        <div
                          onClick={() => {
                            startTransition(() => {
                              setSelectedDate(date); // Set the selected date on click
                            });
                          }}
                          className={`text-text text-center p-2 cursor-pointer ${
                            selectedDate &&
                            selectedDate.toDateString() === date.toDateString()
                              ? "bg-[#E4FFFF]"
                              : "bg-lightgray"
                          }`}
                        >
                          <p className="text-text font-medium">
                            {date.toLocaleDateString("en-US", {
                              weekday: "long",
                              month: "long",
                              day: "numeric",
                            })}
                          </p>
                          <p className="text-green-700 font-bold">AVAILABLE</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
      
                {/* Results Column */}
                <div className="border rounded-lg h-auto col-span-1 md:col-span-2">
                  <div className="results p-5 overflow-y-auto h-screen">
                    {loading ? (
                      <div className="flex items-center justify-center h-screen">
                      <div className='loading w-20 h-20 mx-auto my-auto'>
                        <img src={load} ></img>
                      </div>
                    </div>

                    ) : error ? (
                      <p className="text-red-500">{error}</p>
                    ) : flightData.length > 0 ? (
                      flightData.map((flight, index) => (
                        <div
                          className="result bg-lightgray p-2 rounded-md mb-2"
                          key={index}
                        >
                          <div className="bg-white p-2 h-auto rounded-sm">
                            {flight.legs.map((leg, legIndex) => (
                              <div className="head flex justify-between" key={legIndex}>
                                <div className="option-details flex gap-1 items-center py-1">
                                  <div className="logo w-8 h-8 bg-lightgray rounded-sm">
                                    <img
                                      src={leg.carriers.marketing[0].logoUrl}
                                      alt={leg.carriers.marketing[0].name}
                                      className="w-full rounded-lg"
                                    />
                                  </div>
                                  <div className="name">
                                    <p className="text-text text-sm">
                                      {leg.segments[0].flightNumber} -{" "}
                                      {leg.carriers.marketing[0].name}
                                    </p>
                                  </div>
                                </div>
                                <div className="duration">
                                  <p className="text-text text-sm">
                                    {leg.durationInMinutes} Minutes - {leg.stopCount}{" "}
                                    Stops
                                  </p>
                                </div>
                              </div>
                            ))}
                            <div className="body flex justify-between py-2">
                              <div className="from">
                                <p className="location text-darktext font-bold text-lg">
                                  {flight.legs[0].origin.name}
                                </p>
                                <p className="location text-text font-regular text-sm">
                                  {flight.legs[0].departure}
                                </p>
                              </div>
                              <div className="middle text-center items-center flex flex-col">
                                <div className="icon w-8 h-8">
                                  <img src={airplane} alt="airplane" />
                                </div>
                                <p className="text-sm text-text">TO</p>
                              </div>
                              <div className="to">
                                <p className="location text-darktext font-bold text-lg">
                                  {flight.legs[0].destination.name}
                                </p>
                                <p className="location text-text font-regular text-sm">
                                  {flight.legs[0].arrival}
                                </p>
                              </div>
                            </div>
                            <div className="footer flex justify-between">
                              <div className="option-details flex gap-1 items-center py-1">
                                <div className="icon w-5 h-5 bg-lightgray rounded-sm"></div>
                                <p className="text-sm text-text">
                                  Price: {flight.price.formatted}
                                </p>
                              </div>
                              <button
                                className="bg-[#11A59B] text-white md:px-10 px-4 py-2 text-center"
                                onClick={() => {
                                  if (isFromPlan) {
                                    // Navigate to Confirm_plan with the same payload and states
                                    navigate("/confirm-plan", {
                                      state: {
                                        payload,
                                        destinations,
                                        flightDetails: flight,
                                        isFromFlightSearch: true,
                                      },
                                    });
                                  } else {
                                    // Navigate to package-details as before
                                    navigate("/package-details", {
                                      state: {
                                        payload,
                                        Details,
                                        popular,
                                        flightDetails: flight,
                                        isFromFlightSearch: true,
                                      },
                                    });
                                  }
                                }}
                              >
                                Select Flight
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No flights available for this date.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      
    );
}

export default OptionSearchResult;
